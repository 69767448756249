import indexDBConstants from './indexedDB.constants'

// para guardar la instancia de la db
let db

// bloquear transacciones cuando se está actualizando la db
let disableTransactions = false

/**
 * Obtener la conexión a la base de datos
 * @returns
 */
const getDB = () => {
  return db
}

/**
 * Obtener el valor que bloquea las transacciones
 * @returns
 */
const getDisableTransactions = () => {
  return disableTransactions
}

/**
 * Abre la base de datos y carga los storages
 * @returns
 */
const openDB = () => {
  return new Promise((resolve, reject) => {
    let request = window.indexedDB.open(indexDBConstants.DB_NAME, indexDBConstants.DB_VERSION)

    request.onerror = e => {
      console.log('Error opening db', e)
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Error')
    }

    request.onsuccess = e => {
      db = e.target.result
      resolve(e.target.result)
    }

    // TO DO: handle indexes changes
    request.onupgradeneeded = e => {
      disableTransactions = true
      console.log('onupgradeneeded')
      db = e.target.result
      const transaction = e.target.transaction
      let store
      indexDBConstants.dbStorages.forEach(storage => {
        // creación de stores
        if (!db.objectStoreNames.contains(storage.name)) {
          console.info(`Store ${storage.name} will be created in the IndexedDB Version ${indexDBConstants.DB_VERSION}`)
          store = db.createObjectStore(storage.name,
            { autoIncrement: storage.autoIncrement, keyPath: storage.keyPath })
          // índices
          storage.indexes.forEach(index => {
            if (!store.indexNames.contains(index.name)) {
              console.info(`Store ${storage.name} index ${index.name} will be created in the IndexedDB Version ${indexDBConstants.DB_VERSION}`)
              store.createIndex(index.name, index.props, { unique: index.unique })
            }
          })
        }
      })
      transaction.oncomplete = () => {
        disableTransactions = false
      }
    }
  })
}

const getAll = (storeName) => {
  if (disableTransactions) {
    return []
  }

  return new Promise((resolve) => {
    const transaction = db.transaction([storeName], 'readonly')
    transaction.oncomplete = () => {
      resolve(data)
    }

    let store = transaction.objectStore(storeName)
    let data = []

    store.openCursor().onsuccess = e => {
      let cursor = e.target.result
      if (cursor) {
        data.push(cursor.value)
        cursor.continue()
      }
    }
  })
}

const getOneById = (storeName, id) => {
  if (disableTransactions) {
    return null
  }

  return new Promise((resolve) => {
    const transaction = db.transaction([storeName], 'readonly')
    transaction.oncomplete = () => {
      resolve(data)
    }

    let data = null
    const store = transaction.objectStore(storeName)

    store.get(id).onsuccess = e => {
      data = e.target.result
    }
    
  })
}

const deleteOneById = (storeName, id) => {
  if (disableTransactions) {
    return null
  }

  return new Promise((resolve) => {
    const transaction = db.transaction([storeName], 'readwrite')
    transaction.oncomplete = () => {
      resolve()
    }
    const store = transaction.objectStore(storeName)
    store.delete(id)
  })
}

const deleteAll = (storeName) => {
  if (disableTransactions) {
    return null
  }

  return new Promise((resolve) => {
    const transaction = db.transaction([storeName], 'readwrite')
    transaction.oncomplete = () => {
      resolve()
    }
    const store = transaction.objectStore(storeName)
    store.clear()
  })
}

const add = (storeName, object) => {
  if (disableTransactions) {
    return null
  }

  return new Promise((resolve) => {
    const transaction = db.transaction([storeName], 'readwrite')
    transaction.oncomplete = () => {
      resolve()
    }
    const store = transaction.objectStore(storeName)
    store.add(object)
  })
}

const put = (storeName, object, key = null) => {
  if (disableTransactions) {
    return null
  }

  return new Promise((resolve) => {
    const transaction = db.transaction([storeName], 'readwrite')
    transaction.oncomplete = () => {
      resolve(data)
    }

    let data = null
    const store = transaction.objectStore(storeName)
    if (key == null) {
      store.put(object).onsuccess = e => {
        data = e.target.result
      }
    } else {
      store.put(object, key).onsuccess = e => {
        data = e.target.result
      }
    }
  })
}

export default {
  getDB,
  getDisableTransactions,
  openDB,
  getAll,
  getOneById,
  deleteAll,
  deleteOneById,
  add,
  put
}
