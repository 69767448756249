import * as moment from 'moment'
import i18n from '@/i18n'

const state = {
  details: null,
  warning: null,
  getDateFormat(timestamp, format) {
    const date = moment.unix(timestamp)
    return date.format(format)
  },
  findTextByValueOnList(list, value, valueProp, textProp) {
    if (list && Array.isArray(list) && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        if (list[i][valueProp] === value) {
          return list[i][textProp]
        } else {
          if (list[i].children) {
            let childrenText = this.findTextByValueOnList(list[i].children, value, valueProp, textProp)
            if (childrenText) {
              return childrenText
            }
          }
        }
      }
    }
    return ''
  }
}

const getters = {
  getDetails(state) {
    return state.details
  },
  getWarning(state) {
    return state.warning
  }
}

const actions = {
  setDetails({ commit, state }, { detailData, dataStructure }) {
    if (!detailData) {
      commit('commitDetails', null)
    } else {
      let details = ''
      dataStructure.forEach(d => {
        switch (d.propType) {
          case 'STRING':
            details += `<b>${d.propTitle}:</b>${detailData[d.detailDataProp]}.<br>`;
            break
          case 'DATE':
            details += '<b>' + d.propTitle + ':</b> ' + state.getDateFormat(detailData[d.detailDataProp], 'DD/MM/YYYY HH:mm:ss') + '.' + ' <br> '
            break
          case 'LIST':
            if (detailData[d.detailDataProp] && detailData[d.detailDataProp].length > 0) {
              let text = []
              if (d.propRefs) {
                for (let i = 0; i < detailData[d.detailDataProp].length; i++) {
                  let value = state.findTextByValueOnList(d.propRefs.list, detailData[d.detailDataProp][i][d.propRefs.value], d.propRefs.value, d.propRefs.text)
                  if (value) {
                    text.push(value)
                  } else {
                    text.push(detailData[d.detailDataProp][i])
                  }
                }
              } else {
                text = detailData[d.detailDataProp]
              }
              if (text.length > 5) {
                const count = text.length - 5
                text = text.slice(0, 5)
                details += '<b>' + d.propTitle + ':</b> ' + text.toString().replace('[', '').replace(']', '').replace(/,/g, ', ') + ' ' + i18n.t('treeselect.andMore', { count }) + '<br> '
              } else {
                details += '<b>' + d.propTitle + ':</b> ' + text.toString().replace('[', '').replace(']', '').replace(/,/g, ', ') + '.' + '<br> '
              }
            }
            break
          case 'ITEM':
            if (detailData[d.detailDataProp]) {
              let text
              if (d.propRefs) {
                text = (detailData[d.detailDataProp])[d.propRefs.text]
              } else {
                text = detailData[d.detailDataProp]
              }
              details += '<b>' + d.propTitle + ':</b> ' + text + '.' + '<br> '
            }
            break
        }
      })
      commit('commitDetails', details)
    }
  },
  setWarning({ commit }, warning) {
    commit('commitWarning', warning)
  }
}

const mutations = {
  commitDetails(state, details) {
    state.details = details
  },
  commitWarning(state, warning) {
    state.warning = warning
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
