import messagingApi from '@/api/messaging.api'

export const messagingService = (function () {
  'use strict'

  // NOTIFICACIONES ---------------------------------------------------------

  async function findNotifications (count, page, userId) {
    try {
      let result = await messagingApi.findAllForType(count, page, userId)
      return result.data
    } catch (error) {
      console.log('Error finding notifications')
      return []
    }
  }

  async function getNotificationsTotalCount (userId) {
    try {
      let result = await messagingApi.totalCountForType(userId)
      return result.data
    } catch (error) {
      console.log('Error getting notification total count')
      return 0
    }
  }

  async function getNotReadCount (userId) {
    try {
      let result = await messagingApi.notReadCountForType(userId)
      return result.data.count
    } catch (error) {
      console.log('Error getting not read count nofitifacations')
      return 0
    }
  }

  // ALERTAS ---------------------------------------------------------

  /**
   * Devuelve alertas de la base de datos (paginadas)
   * @param {*} pageSize
   * @param {*} pageNumber
   * @param {*} userId
   */
  async function findAlerts (pageSize, pageNumber, userId) {
    try {
      let result = await messagingApi.findAllAlerts(pageSize, pageNumber, userId)
      return result.data
    } catch (error) {
      console.error('Error al obtener las alertas del usuario')
      return []
    }
  }

  /**
   * Actualiza una alerta
   * @param {*} alert
   */
  async function updateAlert (alert) {
    try {
      await messagingApi.updateAlert(alert)
    } catch (error) {
      console.error('Error al actualizar una alerta', alert)
    }
  }

  /**
   * Marcar todas las alertas del usuario como leídas
   * @param {*} userId
   */
  async function readAllAlerts (userId) {
    try {
      await messagingApi.readAllAlerts(userId)
    } catch (error) {
      console.error('Error al marcar todas las notificaciones como leídas')
    }
  }

  /**
   * Eliminar todas las alertas del usuario
   * @param {*} userId
   */
  async function deleteAllAlerts (userId) {
    try {
      await messagingApi.deleteAllAlerts(userId)
    } catch (error) {
      console.error('Error al marcar todas las notificaciones como leídas')
    }
  }

  /**
   * Para obtener el total de alertas de un usuario
   * @param {*} userId
   */
  async function getAlertsTotal (userId) {
    try {
      const response = await messagingApi.alertTotalCount(userId)
      return response.data
    } catch (error) {
      console.error('Error al obtener la cantidad de alertas para el usuario')
      return 0
    }
  }

  /**
   * Para obtener la cantidad de alertas no leídas
   * @param {*} userId
   */
  async function getAlertsNotRead (userId) {
    try {
      let result = await messagingApi.alertNotReadCount(userId)
      return result.data.count
    } catch (error) {
      console.log('Error getting not read count nofitifacations')
      return 0
    }
  }

  return {
    findNotifications,
    getNotificationsTotalCount,
    getNotReadCount,
    findAlerts,
    updateAlert,
    readAllAlerts,
    deleteAllAlerts,
    getAlertsTotal,
    getAlertsNotRead
  }
})()
