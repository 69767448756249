import mapCurrentStateApi from '@/api/mapCurrentState.api'
import mapReportApi from '@/api/mapReport.api'
import thingApi from '@/api/thing.api'
import indexedDBService from '@/indexedDB/indexedDB.service'
import i18n from '@/i18n'
import { ValueText, ReportConversionUtil, Util, TimeFormat } from '@colven/common-domain-lib/lib'
import reportsUtil from '../utils/reports.util'
import conversionUtil from '../utils/conversion.util'
import stateApi from '../api/state.api'
import mapCurrentStateConstants from '../constants/mapCurrentStateConstants'
import { getTimeOffset } from '@/tools/functions';
import { harvestApi } from '../api/harvest.api'
import harvestTargetService from './harvestTargetService'
import moment from 'moment';

export const mapCurrentStateService = (function () {
  'use strict'

  /**
   * Devuelve datos actualizados a partir del thingId y version
   * @param {*} things
   */
  async function getUpdatedData(things) {
    try {
      const response = await mapCurrentStateApi.getUpdatedData(things)
      const data = response.data
      data.markers = data.markers.map(marker => ({ groupIndex: 0, ...marker }))
      data.references = data.thingMarkers.map(t => ({
        name: t.thingId,
        title: t.thingName,
        groupIndex: 0,
        color: '#339BFB',
        isInterval: false
      }))
      return data
    } catch (e) {
      return {
        markers: [],
        references: [],
        thingMarkers: [],
        thingIdAndVersionData: things
      }
    }
  }

  /**
   * Versión actual de los datos
   * @param {*} thingIds
   */
  async function getCurrentVersion(thingIds) {
    try {
      const response = await mapCurrentStateApi.getCurrentVersion(thingIds)
      return response.data
    } catch (e) {
      return []
    }
  }

  /**
   * Datos para el reporte mapa
   */
  async function getMapReport() {
    try {
      const mapReportResponse = await mapReportApi.getMapReportAll()
      const mapReport = mapReportResponse.data
      return { things: mapReport.things, maps: [mapReport.map], thingIdAndVersionData: mapReport.thingIdAndVersionData }
    } catch (e) {
      return {
        things: [],
        maps: [],
        thingIdAndVersionData: []
      }
    }
  }

  /**
   * Obtener TODOS los íconos personalizados para el mapa
   */
  async function getIcons() {
    try {
      const response = await thingApi.getIcons()
      if (response.data) {
        await indexedDBService.deleteAll('svgIcons')
        for (const icon of response.data) {
          await indexedDBService.add('svgIcons', icon)
        }
        console.info('Custom map icons obtained')
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  async function getIconsMissed(icons) {
    try {
      const response = await thingApi.iconsMissed(icons)
      if (response) {
        for (const icon of response) {
          await indexedDBService.put('svgIcons', icon)
        }
        console.info('Custom map icons obtained')
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /**
   * Obtener los íconos que fueron actualizados
   */
  async function getUpdatedIcons() {
    try {
      const currentIcons = await indexedDBService.getAll('svgIcons')
      const response = await thingApi.getUpdatedIcons(currentIcons.map(element => ({ id: element.id, version: element.version })))
      if (response.data != null && response.data.length > 0) {
        for (const icon of response.data) {
          if (icon.deleted) {
            await indexedDBService.deleteOneById('svgIcons', icon.uiId)
          } else {
            await indexedDBService.put('svgIcons', icon)
          }
        }
        console.info('Custom map icons updated successfully')
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /**
   * Obtener un ícono de la base de datos indexada a partir de la uiId
   * @param {*} uiId
   */
  async function getCustomIcon(uiId) {
    try {
      const icon = await indexedDBService.getOneById('svgIcons', uiId)
      return icon
    } catch (error) {
      console.error(error)
      return null
    }
  }

  function getLastPositionAndZoom() {
    const positionZoom = localStorage.getItem('mapPositionZoom')
    return positionZoom ? JSON.parse(positionZoom) : null
  }

  function setLastPositionAndZoom(positionZoom) {
    localStorage.setItem('mapPositionZoom', JSON.stringify(positionZoom))
  }

  /**
   * Obtener un mapa vacío para evitar la pantalla en blanco cuando se están obteniendo datos
   * @returns
   */
  function getInitialMapConfiguration() {
    return [{
      id: 0,
      name: '',
      groups: [
        {
          name: '',
          title: '',
          referenceByInterval: false,
          series: [
            {
              type: 'MARKERS',
              points: []
            }
          ],
          references: [],
          exclusive: true,
          displayOnStart: true
        }
      ],
      filters: [],
      info: {}
    }]
  }

  const processReportData = async (data) => {
    const offset = getTimeOffset()
    const lang = localStorage.getItem('locale')
    const report = {}
    const { reportData, headerOptions } = await processData(data, lang, offset)
    const headers = mapCurrentStateConstants.headers(lang, headerOptions)
    report.headers = headers
    report.data = reportData
    return report
  }

  /**
     * A partir del array de tramas, crea un array de datos para la tabla del reporte
     * @param data
     * @param lang
     * @param timeOffset
     */
  const processData = async (data, lang, timeOffset) => {
    const headerOptions = {
      engineState: [
        i18n.t('mapReport.ENGINE_STATE.ON', { lang }),
        i18n.t('mapReport.ENGINE_STATE.OFF', { lang })
      ],
      boolean: [
        i18n.t('utils.YES', { lang }),
        i18n.t('utils.NO', { lang })
      ]
    }

    const stateIds = data
      .filter(d => d.stateId && d.stateId !== '' && d.stateId !== null)
      .map(d => d.stateId)

    const states = await stateApi.findAllStatesByIds(stateIds)
    const statesMap = {}
    states.forEach(state => {
      statesMap[state.stateId] = state.type
    })

    const activityIds = [...new Set(data.map(a => a.activityId))]
    const typeKeys = [...new Set(data.map(t => t.typeKey))]
    const sectorIds = [...new Set(data.map(s => s.sectorId))]
    const trailerIds = [...new Set(data.map(t => t.trailerId))]

    const typesMap = await thingApi.getEnterpriseEventConfigurationMultipleTypes(typeKeys)
    const { activities, sectors, trailers, types } = await thingApi.getActivitiesSectorsAndTypesNamesMapped(
      activityIds, sectorIds, typeKeys, trailerIds)

    let positionTimestampConversion
    let positionTimestamp
    let positionTimestampColor
    let comunicationTimestampConversion
    let comunicationTimestamp
    let comunicationTimestampColor
    let activityName
    let sectorName
    let typeName
    let trailerName
    const reportData = data.map(d => {
      // Conversiones y acondicionamiento de datos
      positionTimestampConversion = ReportConversionUtil.applyTimezone(d.trackTimestamp, timeOffset)
      positionTimestamp = positionTimestampConversion.dateString + ' ' + positionTimestampConversion.timeString
      positionTimestampColor = reportsUtil.getTimestampColor(d.trackTimestamp)
      comunicationTimestampConversion = ReportConversionUtil.applyTimezone(d.timestamp, timeOffset)
      comunicationTimestamp = comunicationTimestampConversion.dateString + ' ' + comunicationTimestampConversion.timeString
      comunicationTimestampColor = reportsUtil.getTimestampColor(d.timestamp)

      let color = d.stateColor
      if (d.typeKey) {
        const typeValue = typesMap[d.typeKey]
        const state = typeValue ? typesMap[d.typeKey].find(t => t.stateType === statesMap[d.stateId]) : null
        color = state && state.stateColor ? state.stateColor : color
      }

      // actividad
      activityName = d.activityId && activities[d.activityId].key
        ? i18n.t(`mapReport.ACTIVITIES.${activities[d.activityId].key}`, { lang })
        : (activities[d.activityId] && activities[d.activityId].name ? activities[d.activityId].name : undefined)
      // sector
      sectorName = d.sectorId && sectors[d.sectorId]
        ? sectors[d.sectorId].name
        : undefined
      // type
      typeName = d.typeKey && types[d.typeKey]
        ? types[d.typeKey].name[lang]
        : undefined
      // trailer
      trailerName = d.trailerId && trailers[d.trailerId]
        ? trailers[d.trailerId].name
        : undefined

      return {
        // Id de la cosa
        thingId: d.thingId,
        // Nombre de la cosa
        thingName: d.thingName,
        // tipo
        typeName: typeName,
        // Actividad de la cosa
        activityName: activityName,
        // sector
        sectorName: sectorName,
        // Empresa
        enterpriseName: d.enterpriseName,
        // Grupo
        groupName: d.groupName,
        // trailer
        trailerId: d.trailerId,
        trailerName: trailerName,
        // Latitud y longitud
        lat: d.lat,
        long: d.long,
        // Último evento
        eventName: d.eventName ? d.eventName[lang] : undefined,
        eventIcon: 'fas fa-square',
        eventColor: d.eventColor ? d.eventColor : '#000000',
        // evento del cliente
        clientEvent: d.clientEvent != null ? d.clientEvent : undefined,
        // Último estado
        stateName: d.stateName ? d.stateName[lang] : undefined,
        stateColor: color || '#000000',
        // Estado del motor
        engineState: {
          text: d.engineRunning === true
            ? i18n.t('mapReport.ENGINE_STATE.ON', { lang })
            : (d.engineRunning === false ? i18n.t('mapReport.ENGINE_STATE.OFF', { lang }) : undefined),
          value: d.engineRunning
        },
        engineStateColor: d.engineRunning === true
          ? '#66BB6A'
          : (d.engineRunning === false ? '#263238' : '#263238'),
        // Velocidad
        speed: d.speed != null ? new ValueText(d.speed,
          conversionUtil.speedDetentionToStringTranslated(d.speed, d.direction,
            d.stopped, d.stoppedTime, d.trackTimestamp, lang)) : undefined,
        // RPM
        rpm: new ValueText(parseInt(d.rpm, 10) || 0, d.rpm || undefined),
        // Temperatura
        temperature: d.temperaturePM != null
          ? new ValueText(d.temperaturePM, conversionUtil.temperatureToStringTranslated(lang, d.temperaturePM))
          : undefined,
        // horometro
        hourMeter: d.hourMeter != null
          ? new ValueText(d.hourMeter, ReportConversionUtil.secondsToStringFormatted(d.hourMeter, TimeFormat.HH_MM_SS))
          : undefined,
        // odometro
        odometer: d.odometer != null
          ? new ValueText(Util.roundDecimals(d.odometer), `${Util.roundDecimals(d.odometer)} Km`)
          : undefined,
        // Fecha y hora de la última comunicación
        comunicationTimestamp,
        comunicationTimestampColor,
        // Fecha y hora de la última posición
        positionTimestamp,
        positionTimestampColor,
        // Referencia (puntos de interés)
        reference: '',
        // Ubicación
        ubication: '',
        // Conductor
        driverId: d.driverId,
        driverName: d.driverExists
          ? d.driverName
          : i18n.t('mapReport.DRIVER_NOT_IDENTIFIED', { lang, args: { driverId: d.driverId } }),
        // UM y atributos
        driveUnitName: d.driveUnitName,
        farm: d.farm,
        ecologicalUnit: d.ecologicalUnit,
        harvestSpeed: new ValueText(d.harvestSpeed, conversionUtil.speedToStringTranslated(d.harvestSpeed, -1, lang)),
        variety: d.variety,
        age: d.age,
        area: d.area,
        tch: d.tch,
        tchp: d.tchp,
        tchr: d.tchr,
        grooves: d.grooves,
        kgPerGroove: d.kgPerGroove,
        groovesPerHectare: d.groovesPerHectare,
        machineEfficiency: d.machineEfficiency,
        headWidth: d.headWidth,
        distanceToMill: d.distanceToMill,
        plantingWidth: d.plantingWidth,
        nivelationWidth: d.nivelationWidth,
        // PM Vigia
        rpmPM: d.rpmPM,
        oilSensorPM: d.oilSensorPM != null
          ? new ValueText(d.oilSensorPM, reportsUtil.getBooleanText(d.oilSensorPM, lang))
          : undefined,
        actuatorPM: d.actuatorPM != null
          ? new ValueText(d.actuatorPM, reportsUtil.getBooleanText(d.actuatorPM, lang))
          : undefined,
        batteryTensionPM: d.batteryTensionPM,
        temperaturePM: d.temperature != null
          ? new ValueText(d.temperature, conversionUtil.temperatureToStringTranslated(lang, d.temperature))
          : undefined
      }
    })

    return { reportData, headerOptions }
  }

  async function getVinculatedTrucks() {
    const result = await mapCurrentStateApi.getVinculatedTrucks();
    let timezone = parseInt(localStorage.getItem('timezone'))
    // aplico timezone
    let date;
    result.data.forEach(element => {
      date = new Date(element.trackTimestamp.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
      date.setHours(date.getHours() + timezone);
      element.trackTimestamp = date.toLocaleString()
    })

    return result
  }
  async function getOfflineHarvesters(activity, thingType, workFronts, states) {
    const result = await mapReportApi.getOfflineHarvesters(activity, thingType, workFronts, states)

    let timezone = parseInt(localStorage.getItem('timezone'))
    // aplico timezone
    let date;
    result.data.forEach(element => {
      date = new Date(element.trackTimestamp.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
      date.setHours(date.getHours() + timezone);
      element.trackTimestamp = date.toLocaleString()

      date = new Date(element.timestamp.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
      date.setHours(date.getHours() + timezone);
      element.timestamp = date.toLocaleString()
    })


    return result
  }

  async function getThingStateByIdThings(idThings, projection) {
    let result = await mapCurrentStateApi.getThingStateByIdThings(idThings, projection)
    return result.data
  }

  /**Obtiene el la data de la grilla de velocidad en eficiencia */

  async function getHarvestSpeedReport() {

    let tableData = []
    try {
      // Estado de cosechadoras trabajando (UM, Fecha de posición, Fecha de comunicación, Velocidad de ref de UM, velocidad real)
      let harvesterWorkingState = await mapCurrentStateApi.getHarvesterSpeedReport();

      //Toneladas por hora de referencia
      let tonsPerHour;
      let speedPorcentageValue;

      const enterpriseId = JSON.parse(localStorage.getItem("enterpriseId"));
      let activeHarvest = await harvestApi.getActiveHarvestData(enterpriseId);

      if (activeHarvest.data[0].harvests[0].name) {
        let harvestConfig = await harvestTargetService.getHarvestConfigByHarvestName(activeHarvest.data[0].harvests[0].name)
        tonsPerHour = harvestConfig.tonsPerHour.toString();
        speedPorcentageValue = harvestConfig.speedPorcentageValue
      } else {
        tonsPerHour = 0
        speedPorcentageValue = 0;
      }

      //determino tableData
      if (harvesterWorkingState.data) {
        let speedRangeIcon;
        let actualSpeed;
        let referenceSpeed;
        let trackDatesFormat;
        harvesterWorkingState.data.forEach(item => {
          trackDatesFormat = getTrackDateFormat(item.timestamp, item.trackTimestamp);

          if (item.trackData) {
            actualSpeed = item.trackData.TELE_speed_KPH || 0;
            referenceSpeed = item.trackData.TELE_drive_unit.properties ? (item.trackData.TELE_drive_unit.properties.harvestSpeed ? item.trackData.TELE_drive_unit.properties.harvestSpeed : 0) : 0;
            speedRangeIcon = getSpeedRangeIcon(actualSpeed, referenceSpeed, speedPorcentageValue, item.trackTimestamp, item.trackData.lastMaxMinSpeedTimestamp)

            tableData.push({
              timestamp: trackDatesFormat.timestamp,
              trackTimestamp: trackDatesFormat.trackTimestamp,
              trackTimestampColor: trackDatesFormat.trackTimestampColor,
              timestampColor: trackDatesFormat.timestampColor,
              thingName: item.thingName,
              harvestFront: item.harvestFront,
              driveUnit: item.trackData.TELE_drive_unit.properties ? item.trackData.TELE_drive_unit.properties.Name : '',
              referenceSpeed: parseFloat(referenceSpeed).toFixed(2),
              actualSpeed: parseFloat(actualSpeed).toFixed(2),
              tonsPerHour: tonsPerHour,
              actualTonsPerHour: item.trackData.TELE_drive_unit.properties && tonsPerHour && item.trackData.TELE_speed_KPH != 0 ? getActualTonsPerHour(tonsPerHour, referenceSpeed, item.trackData.TELE_speed_KPH) : 0,
              driver: item.trackData.TELE_driver_id ? item.trackData.TELE_driver_id : '',
              icon: speedRangeIcon.icon,
              iconColor: speedRangeIcon.color,
              state: speedRangeIcon.state,
            })
          } else {
            speedRangeIcon = getSpeedRangeIcon(0, 0, speedPorcentageValue, item.trackTimestamp, null)

            tableData.push({
              timestamp: trackDatesFormat.timestamp,
              trackTimestamp: trackDatesFormat.trackTimestamp,
              trackTimestampColor: trackDatesFormat.trackTimestampColor,
              timestampColor: trackDatesFormat.timestampColor,
              thingName: item.thingName,
              harvestFront: item.harvestFront,
              driveUnit: '',
              referenceSpeed: 0,
              actualSpeed: 0,
              tonsPerHour: tonsPerHour,
              actualTonsPerHour: 0,
              driver: '',
              icon: speedRangeIcon.icon,
              iconColor: speedRangeIcon.color,
              state: speedRangeIcon.state,
            })
          }

        })
      }

    } catch (e) {
      console.log(e)
    }
    return tableData;

  }

  /**Obtiene el formato de la fecha de comunicacion y de posición de la trama, colores, formato dd/mm/aaa hh:mm y aplica timezone. 
   * @param timestamp - fecha de comunicación (UNIX)
   * @param trackTimestamp - fecha de posición (UNIX)
  */
  function getTrackDateFormat(timestamp, trackTimestamp) {
    const today = new Date().getTime() / 1000
    let result = {
      trackTimestamp: '',
      trackTimestampColor: '',
      timestamp: '',
      timestampColor: '',
    }
    //Obtiene el color
    if ((today - 180) >= trackTimestamp && (today - 300) < trackTimestamp) {
      result.trackTimestampColor = "#FFEB3B"
    } else {
      if ((today - 300) >= trackTimestamp) {
        result.trackTimestampColor = "#F44336"
      } else {
        result.trackTimestampColor = "#63bd18"
      }

    }
    if ((today - 180) >= timestamp && (today - 300) < timestamp) {
      result.timestampColor = "#FFEB3B"
    } else if ((today - 300) >= timestamp) {
      result.timestampColor = "#F44336"
    } else {
      result.timestampColor = "#63bd18"
    }

    let trackTimestampDate = new Date((moment(trackTimestamp * 1000).format('DD/MM/YYYY HH:mm:ss')).replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
    trackTimestampDate.setHours(trackTimestampDate.getHours());
    result.trackTimestamp = moment(trackTimestampDate).format('DD/MM/YYYY HH:mm:ss')
    let timestampDate = new Date((moment(timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')).replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
    timestampDate.setHours(timestampDate.getHours());
    result.timestamp = moment(timestampDate).format('DD/MM/YYYY HH:mm:ss')

    return result;
  }
  /**
   * Obtiene el icono y el color de este correspondiente para cada fila del reporte. 
   * @param {Float} actualSpeed - Velocidad actual obtenida en la trama
   * @param {Float} referenceSpeed  - velocidad de referencia de la UM obtenida en la trama.
   * @param {Int} speedPorcentage - porcentaje para el cálculo de velocidad obtenido de la colección 'harvestConfig'
   * @param {*} trackTimeStamp - Fecha de posición (UNIX)
   * @param {*} lastMaxMinSpeedTimestamp - fecha -Parámetro obtenido de la trama (UNIX)
   * @returns 
   */
  function getSpeedRangeIcon(actualSpeed, referenceSpeed, speedPorcentage, trackTimeStamp, lastMaxMinSpeedTimestamp) {
    let state;
    let floatRealSpeed = parseFloat(actualSpeed);
    let floatReferenceSpeed;
    if (referenceSpeed === 0) {
      floatReferenceSpeed = 0
    } else {
      floatReferenceSpeed = parseFloat(referenceSpeed);
    }

    let maxRangeSpeedValue = floatReferenceSpeed - ((floatReferenceSpeed * parseFloat(speedPorcentage)) / 100)
    if (floatRealSpeed >= floatReferenceSpeed && floatRealSpeed <= maxRangeSpeedValue) {
      state = 'acceptable'
    } else {
      if (floatRealSpeed > floatReferenceSpeed) {
        state = 'above'
      } else {
        state = 'below'
      }
    }
    let result = { state: '', icon: '', color: null }
    switch (state) {
      case 'acceptable':
        state = 'acceptable';
        result.color = '#66BB6A'
        result.icon = 'mdi mdi-check-circle-outline'
        break;
      case 'above':
        if (validateAcceptableRange(trackTimeStamp, lastMaxMinSpeedTimestamp)) {
          result.state = 'acceptable';
          result.color = '#66BB6A'
          result.icon = 'mdi mdi-check-circle-outline'
        } else {
          result.state = state;
          result.icon = 'mdi mdi-arrow-up-thin-circle-outline';
          result.color = '#EF5350'
        }
        break;
      case 'below':
        if (validateAcceptableRange(trackTimeStamp, lastMaxMinSpeedTimestamp)) {
          result.state = 'acceptable';
          result.color = '#66BB6A'
          result.icon = 'mdi mdi-check-circle-outline'
        } else {
          result.state = state;
          result.icon = 'mdi mdi-arrow-down-thin-circle-outline';
          result.color = '#EF5350'
        }
        break;

    }
    return result;

  }
  /**
   * Valida si el estado de la velocidad está dentro del rango de inmunidad aceptable (5min)
   * @param {*} trackTimeStamp - fecha de posición (UNIX)
   * @param {*} lastMaxMinSpeedTimestamp 
   * @returns 
 */
  function validateAcceptableRange(trackTimeStamp, lastMaxMinSpeedTimestamp) {
    if (!lastMaxMinSpeedTimestamp) {
      return true;
    } else {
      var diferenciaEnMinutos = Math.floor((lastMaxMinSpeedTimestamp - trackTimeStamp) / 60);
      return diferenciaEnMinutos > 5;
    }

  }

  /**Obtiebne las toneladas por hora actuales 
   * @param {Int} tonsPerHour - toneladas por hora de referencia. Obtenidas en la colección harvestConfig
   * @param {Float} referenceSpeed - Velocidad de referencia de la UM. Obtenida en la trama
   * @param {Float} actualSpeed - Velocidad actual de la cosechadora  
   */

  function getActualTonsPerHour(tonsPerHour, referenceSpeed, actualSpeed) {
    if (referenceSpeed !== 0) {
      let result = parseFloat((actualSpeed * tonsPerHour) / referenceSpeed).toFixed(2)
      return result;
    } else { return 0 }
  }



  return {
    getHarvestSpeedReport,
    getThingStateByIdThings,
    getUpdatedData,
    getCurrentVersion,
    getMapReport,
    getIcons,
    getCustomIcon,
    getUpdatedIcons,
    setLastPositionAndZoom,
    getLastPositionAndZoom,
    getIconsMissed,
    getInitialMapConfiguration,
    processReportData,
    getVinculatedTrucks,
    getOfflineHarvesters
  }
})()
