import service from '@/middleware'

/**
 * Llamadas al api de map-current-state
 */
const mapCurrentStateApi = {
  /**
   * Devuelve datos actualizados a partir del thingId y version
   * @param {*} things
   */
  async getUpdatedData(things) {
    const result = await service.http.post('/api/map-current-state/updated-data', things)
    return result
  },
  /**
   * Versión actual para las cosas enviadas en el body
   * @param {*} thingIds
   */
  async getCurrentVersion(thingIds) {
    const result = await service.http.post('/api/map-current-state/current-version', thingIds)
    return result
  },

  async getVinculatedTrucks() {
    const result = await service.http.get('/api/current-state/vinculated-trucks')
    return result
  },

  async getThingStateByIdThings(idThings, projection) {
    let result = await service.http.post('/api/current-state/current-state-byThings', { idThings, projection })
    return result
  },

  async getHarvesterSpeedReport() {
    const result = await service.http.get('/api/current-state/harvester-speed-report')
    return result
  },

  async getTrackDataByThingId(thingIds) {
    const result = await service.http.post('/api/current-state/getTrackDataByThingId', { thingIds })
    return result.data;
  },

  async getStatusByCommunicationDate(idThings, from) {
    const result = await service.http.post('/api/current-state/getStatusByCommunicationDate', { idThings, from })
    return result.data;

  },

  async getHarvestFrontStatus(harvestFrontIds) {
    const { data } = await service.http.post("api/harvestfrontcurrentstate/harvestFrontStatus", { harvestFrontIds });
    return data;
  }
}

export default mapCurrentStateApi
