import store from '../store/store'

export const privilegesService = (function () {
  'use strict'
  function validatePrivilegesOr (conditions) {
    const permissions = store.getters['auth/getPrivileges'] || []
    let result = false
    if (conditions && conditions.length > 0) {
      conditions.forEach((condition) => {
        result = result || permissions.includes(condition)
      })
    }
    return result
  }

  function validatePrivilegesAnd (conditions) {
    const permissions = store.getters['auth/getPrivileges'] || []
   let result = true
    if (conditions && conditions.length > 0) {
      conditions.forEach((condition) => {
        result = result && permissions.includes(condition)
       
      })
    }
    return !!result
  }

  function validatePrivileges (conditions) {
    let result = true
    let admin = store.getters['auth/getAdmin']
    if (!admin) {
      if (conditions.and) {
        result = validatePrivilegesAnd(conditions.and)
      }
      if (result && conditions.or) {
        result = validatePrivilegesOr(conditions.or)
      }
    }
    return result
  }

  return {
    validatePrivilegesOr,
    validatePrivilegesAnd,
    validatePrivileges
  }
})()
