import { SnackbarStyle } from '@/constants/constants'
import Vue from 'vue'

export const state = () => ({
  visible: false,
  text: '',
  timeout: 0,
  multiline: false,
  style: SnackbarStyle.ERROR,
  timeoutInstance: null,
  showCustomBtn: false,
  customBtnIcon: null,
  customBtnTooltip: null,
  customBtnAction: () => { return null }
})

const getters = {
  getVisible: (state) => {
    return state.visible
  },
  getText: (state) => {
    return state.text
  },
  getTimeout: (state) => {
    return state.timeout
  },
  getMultiline: (state) => {
    return state.multiline
  },
  getShowCustomBtn: (state) => {
    return state.showCustomBtn
  },
  getCustomBtnIcon: (state) => {
    return state.customBtnIcon
  },
  getCustomBtnTooltip: (state) => {
    return state.customBtnTooltip
  },
  getCustomBtnAction: (state) => {
    return state.customBtnAction
  }
}

const actions = {
  setVisible ({ commit }, visible) {
    commit('commitVisible', { visible })
  },
  setText ({ commit }, text) {
    commit('commitText', { text })
  },
  setTimeout ({ commit }, timeout) {
    commit('commitTimeout', { timeout })
  },
  setMultiline ({ commit }, multiline) {
    commit('commitMultiline', { multiline })
  },
  setStyle ({ commit }, style) {
    commit('commitStyle', { style })
  },
  setShowCustomBtn ({ commit }, showCustomBtn) {
    commit('commitShowCustomBtn', { showCustomBtn })
  },
  setCustomBtnIcon ({ commit }, customBtnIcon) {
    commit('commitCustomBtnIcon', { customBtnIcon })
  },
  setCustomBtnTooltip ({ commit }, customBtnTooltip) {
    commit('commitCustomBtnTooltip', { customBtnTooltip })
  },
  setCustomBtnAction ({ commit }, customBtnAction) {
    commit('commitCustomBtnAction', { customBtnAction })
  },
  showSnackbar ({ commit }, payload) {
    commit('commitText', { text: payload.text })
    commit('commitMultiline', { multiline: payload.text.length > 50 || payload.multiline })
    commit('commitStyle', { style: payload.style })
    commit('clearTimeout')

    if (payload.timeout) {
      commit('commitTimeoutInstance', {
        timeoutInstance: setTimeout(() => {
          this.dispatch('snackbar/closeSnackbar')
        }, payload.timeout)
      })
    } else {
      commit('commitTimeout', { timeout: 0 })
    }

    // botón personalizado
    if (payload.showCustomBtn) {
      if (payload.customBtnIcon != null && payload.customBtnTooltip != null && payload.customBtnAction != null) {
        commit('commitShowCustomBtn', { showCustomBtn: true })
        commit('commitCustomBtnIcon', { customBtnIcon: payload.customBtnIcon })
        commit('commitCustomBtnTooltip', { customBtnTooltip: payload.customBtnTooltip })
        commit('commitCustomBtnAction', { customBtnAction: payload.customBtnAction })
      }
    } else {
      commit('commitShowCustomBtn', { showCustomBtn: false })
      commit('commitCustomBtnIcon', { customBtnIcon: null })
      commit('commitCustomBtnTooltip', { customBtnTooltip: null })
      commit('commitCustomBtnAction', { customBtnAction: () => { return null } })
    }

    commit('commitVisible', { visible: true })
  },
  closeSnackbar ({ commit }) {
    commit('commitVisible', { visible: false })
    Vue.nextTick(function () {
      commit('closeSnackbar')
    })
  }
}

const mutations = {
  commitVisible (state, { visible }) {
    state.visible = visible
  },
  commitText (state, { text }) {
    state.text = text
  },
  commitTimeout (state, { timeout }) {
    state.timeout = timeout
  },
  commitTimeoutInstance (state, { timeoutInstance }) {
    state.timeoutInstance = timeoutInstance
  },
  commitMultiline (state, { multiline }) {
    state.multiline = multiline
  },
  commitStyle (state, { style }) {
    state.style = style
  },
  showSnackbar (state, payload) {
    state.text = payload.text
    state.multiline = payload.text.length > 50
    state.style = payload.style
    if (payload.multiline) {
      state.multiline = payload.multiline
    }
    if (state.timeoutInstance) {
      clearTimeout(state.timeoutInstance)
      state.timeoutInstance = null
    }
    if (payload.timeout) {
      state.timeoutInstance = setTimeout(() => {
        this.commit('snackbar/closeSnackbar')
      }, payload.timeout)
    } else {
      state.timeout = 0
    }

    state.visible = true
  },
  closeSnackbar (state) {
    state.visible = false
    state.multiline = false
    state.timeout = 0
    state.text = ''
    state.showCustomBtn = false
    state.setCustomBtnIcon = null
    state.setCustomBtnTooltip = null
    state.setCustomBtnAction = () => { return null }
  },
  commitShowCustomBtn (state, { showCustomBtn }) {
    state.showCustomBtn = showCustomBtn
  },
  commitCustomBtnIcon (state, { customBtnIcon }) {
    state.customBtnIcon = customBtnIcon
  },
  commitCustomBtnTooltip (state, { customBtnTooltip }) {
    state.customBtnTooltip = customBtnTooltip
  },
  commitCustomBtnAction (state, { customBtnAction }) {
    state.customBtnAction = customBtnAction
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
