import store from './store/store'
import i18n from './i18n'
import { SnackbarStyle } from '@/constants/constants'
import axios from 'axios'
import { Service } from 'axios-middleware'
import html2canvas from 'html2canvas'
import router from '@/router'

const instance = axios.create({
  baseURL: process.env.API_GATEWAY_URL,
  proxy: false
})

const service = new Service(instance)

service.register({
  onRequest (config) {
    const jwt = localStorage.getItem('token')
    const firebase = store.getters['messaging/getFirebaseToken']
    const userId = localStorage.getItem('userId')
    const timezone = localStorage.getItem('timezone')
    if (jwt) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        'authorization': jwt,
        'sessionToken': jwt,
        'accept-language': localStorage.getItem('locale'),
        'firebase-token': firebase,
        'user-id': userId,
        'time-offset': timezone ? parseInt(timezone) : -(new Date().getTimezoneOffset() / 60)
      }
    }
    return config
  },
  onSync (promise) {
    return promise
  },
  onResponse (response) {
    // Este código se ejecuta cuando se obtiene una respuesta con un status code exitoso
    return response
  },
  onResponseError (error) {
    if (error && error.response && error.response.status === 401) {
      router.push('/login').catch((e) => { console.log(e) })
    }
    // Este código se ejecuta cuando se obtiene una respuesta con un status code de error
    const responseBody = JSON.parse(error.response.data)
    const errorMsg = i18n.t(responseBody.message)
    store.dispatch('snackbar/showSnackbar',
      { visible: true,
        text: errorMsg,
        timeout: 6000,
        style: SnackbarStyle.ERROR,
        showCustomBtn: true,
        customBtnIcon: 'mdi-email-send',
        customBtnTooltip: i18n.t('reportBug.report'),
        customBtnAction: () => {
          html2canvas(document.body).then(function (canvas) {
            const base64 = canvas.toDataURL().split(';base64,')[1]
            store.dispatch('reportBug/setAutoScreenshot', base64)
            store.dispatch('reportBug/setErrorMsg', errorMsg)
            store.dispatch('reportBug/setErrorRequestConfig', {
              method: error.config ? error.config.method : undefined,
              url: error.config ? error.config.url : undefined,
              headers: error.config ? error.config.headers : undefined,
              data: error.config ? error.config.data : undefined,
              statusCode: error.response ? error.response.status : undefined
            })
            store.dispatch('reportBug/setFieldsRequired', false)
            store.dispatch('reportBug/openReportBugDialog')
          })
        }
      })
    return Promise.reject(error)
  }
})

export default service
