import { mapActions, mapMutations } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'

export default {
  name: 'SidebarComponent',
  props: {
    items: Array
  },
  data () {
    return {
    }
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  computed: {
  },
  created () {
  },
  beforeDestroy () {
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapMutations('snackbar', [
      'showSnackbar',
      'closeSnackbar'
    ]),
    goTo (route) {
      this.$router.push(route).catch(err => {
        console.log(err)
      })
    },
    contact () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    }
  }
}
