import moment from 'moment'
import i18n from '../i18n'
import { TimeRanges } from '@colven/common-domain-lib/lib'

const calculateColumnWidth = (content) => {
  let fixWidth = 0

  if (content.length <= 25) {
    fixWidth = (content.length + 1) * 9
  } else {
    fixWidth = (content.length + 1) * 8
  }

  if (fixWidth < 100) {
    fixWidth = 100
  }

  // agregamos el espacio que ocupa la flecha de ordenamiento
  fixWidth = fixWidth + 15

  return fixWidth
}

/**
 * Obtener el color para un timestamp
 * VERDE: timestamp menor a 10 minutos antes de la hora actual
 * AMARILLO: entre 10 y 60 minutos antes de la hora actual
 * ROJO: mayor a 60 minutos antes de la hora actual
 * @param timestamp
 * @returns
 */
const getTimestampColor = (timestamp) => {
  const nowSubstract10 = moment().utc().subtract(10, 'minutes')
  const nowSubstract60 = moment().utc().subtract(60, 'minutes')
  const momentTimestamp = moment.unix(timestamp)
  if (momentTimestamp.isSameOrAfter(nowSubstract10)) {
    return '#66BB6A'
  } else if (momentTimestamp.isSameOrAfter(nowSubstract60) &&
    momentTimestamp.isBefore(nowSubstract10)) {
    return '#d6c105'
  } else if (momentTimestamp.isBefore(nowSubstract60)) {
    return '#ef5350'
  } else {
    return undefined
  }
}

/**
 * Traduce un boolean a un string YES or NO
 * @param booleanData
 * @param lang
 * @returns
 */
const getBooleanText = (booleanData, lang) => {
  let translation = ''
  if (booleanData === true) {
    translation = i18n.t('utils.YES', { lang })
  } else if (booleanData === false) {
    translation = i18n.t('utils.NO', { lang })
  }
  return translation
}

/**
 * Evalua los rangos precalculados para los dashboards
 * @param timeRange
 * @returns
 */
const getDashboardByPass = (timeRange) => {
  return timeRange === TimeRanges.LAST_HOUR ||
    timeRange === TimeRanges.LAST_SIX_HOUR ||
    timeRange === TimeRanges.LAST_WEEK ||
    timeRange === TimeRanges.ENTERPRISE ||
    timeRange === TimeRanges.TODAY ||
    timeRange === TimeRanges.YESTERDAY ||
    timeRange === TimeRanges.ENTERPRISE_PREVIOUS
}

export default {
  getDashboardByPass,
  getBooleanText,
  getTimestampColor,
  calculateColumnWidth
}
