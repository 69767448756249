/* eslint-disable indent */
import { FilterType, DataType, CellType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const headers = (lang, headersOptions) => {
    return [
        {
            text: i18n.t('mapReport.HEADERS.EQUIPMENT', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 100,
            value: 'thingName'
        },
        {
            text: i18n.t('mapReport.HEADERS.EQUIPMENT_TYPE', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 100,
            value: 'typeName'
        },
        {
            text: i18n.t('mapReport.HEADERS.GROUP', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 135,
            value: 'groupName'
        },
        {
            text: i18n.t('mapReport.HEADERS.ENTERPRISE', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 135,
            value: 'enterpriseName'
        },
        {
            text: i18n.t('mapReport.HEADERS.SECTOR', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 100,
            value: 'sectorName'
        },
        {
            text: i18n.t('mapReport.HEADERS.ACTIVITY', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 100,
            value: 'activityName'
        },
        {
            text: i18n.t('mapReport.HEADERS.TRAILER_ID', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 135,
            value: 'trailerId'
        },
        {
            text: i18n.t('mapReport.HEADERS.TRAILER_NAME', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 135,
            value: 'trailerName'
        },
        {
            text: i18n.t('mapReport.HEADERS.EVENT', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            cellType: CellType.iconText,
            width: 150,
            cellConfig: {
                icon: 'eventIcon',
                color: 'eventColor'
            },
            value: 'eventName'
        },
        {
            text: i18n.t('mapReport.HEADERS.STATE', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            cellType: CellType.textColor,
            cellConfig: {
                color: 'stateColor'
            },
            value: 'stateName',
            width: 100
        },
        {
            text: i18n.t('mapReport.HEADERS.CLIENT_EVENT', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'clientEvent'
        },
        {
            text: i18n.t('mapReport.HEADERS.ENGINE_STATE', { lang }),
            align: 'left',
            filterType: FilterType.multiSelect,
            selected: true,
            sortable: true,
            options: headersOptions.engineState,
            type: DataType.boolean,
            cellType: CellType.textBackgroundColor,
            width: 140,
            cellConfig: {
                round: true,
                color: 'engineStateColor'
            },
            value: 'engineState'
        },
        {
            text: i18n.t('mapReport.HEADERS.SPEED', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            width: 170,
            value: 'speed'
        },
        {
            text: i18n.t('mapReport.HEADERS.RPM', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'rpm'
        },
        {
            text: i18n.t('mapReport.HEADERS.TEMPERATURE', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            width: 150,
            value: 'temperature'
        },
        {
            text: i18n.t('mapReport.HEADERS.ODOMETER', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'odometer'
        },
        {
            text: i18n.t('mapReport.HEADERS.HOURMETER', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'hourMeter'
        },
        {
            text: i18n.t('mapReport.HEADERS.POSITION_TIMESTAMP', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            cellType: CellType.textBackgroundColor,
            width: 200,
            cellConfig: {
                round: false,
                outlined: true,
                color: 'positionTimestampColor'
            },
            value: 'positionTimestamp'
        },
        {
            text: i18n.t('mapReport.HEADERS.COMUNICATION_TIMESTAMP', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            cellType: CellType.textBackgroundColor,
            width: 210,
            cellConfig: {
                round: false,
                outlined: true,
                color: 'comunicationTimestampColor'
            },
            value: 'comunicationTimestamp'
        },
        {
            text: i18n.t('mapReport.HEADERS.UBICATION', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'ubication',
            // spinner: true,
            width: 200
            // cellConfig: {
            //     spinnerColor: '#03A9F4',
            // },
        },
        {
            text: i18n.t('mapReport.HEADERS.REFERENCE', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'reference',
            // spinner: true,
            width: 200
            // cellConfig: {
            //     spinnerColor: '#03A9F4',
            // },
        },
        {
            text: i18n.t('mapReport.HEADERS.DRIVER', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'driverName',
            width: 100
        },
        {
            text: i18n.t('mapReport.HEADERS.DRIVE_UNIT', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'driveUnitName',
            width: 200
        },
        {
            text: i18n.t('mapReport.HEADERS.farm', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 80,
            value: 'farm'
        },
        {
            text: i18n.t('mapReport.HEADERS.ecologicalUnit', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'ecologicalUnit',
            width: 140
        },
        {
            text: i18n.t('mapReport.HEADERS.variety', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            value: 'variety',
            width: 150
        },
        {
            text: i18n.t('mapReport.HEADERS.age', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.string,
            width: 70,
            value: 'age'
        },
        {
            text: i18n.t('mapReport.HEADERS.harvestSpeed', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'harvestSpeed',
            width: 200
        },
        {
            text: i18n.t('mapReport.HEADERS.netArea', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'area',
            width: 100
        },
        {
            text: i18n.t('mapReport.HEADERS.TCHP', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            width: 80,
            value: 'tchp'
        },
        {
            text: i18n.t('mapReport.HEADERS.netGrooves', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'grooves',
            width: 120
        },
        {
            text: i18n.t('mapReport.HEADERS.TCH', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'tch',
            width: 100
        },
        {
            text: i18n.t('mapReport.HEADERS.TCHR', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'tchr',
            width: 100
        },
        {
            text: i18n.t('mapReport.HEADERS.kgGroove', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'kgPerGroove',
            width: 115
        },
        {
            text: i18n.t('mapReport.HEADERS.hectareGroove', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'groovesPerHectare',
            width: 160
        },
        {
            text: i18n.t('mapReport.HEADERS.machineEfficiency', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'machineEfficiency',
            width: 180
        },
        {
            text: i18n.t('mapReport.HEADERS.distanceMill', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'distanceToMill',
            width: 170
        },
        {
            text: i18n.t('mapReport.HEADERS.harvestWidth', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'headWidth',
            width: 140
        },
        {
            text: i18n.t('mapReport.HEADERS.plantingWidth', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'plantingWidth',
            width: 170
        },
        {
            text: i18n.t('mapReport.HEADERS.nivelationWidth', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'nivelationWidth',
            width: 170
        },
        // PROTECTOR MOTOR VIGIA
        {
            text: i18n.t('mapReport.HEADERS.PM_RPM', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'rpmPM'
        },
        {
            text: i18n.t('mapReport.HEADERS.PM_OIL_SENSOR', { lang }),
            align: 'left',
            filterType: FilterType.multiSelect,
            options: headersOptions.boolean,
            selected: true,
            sortable: true,
            type: DataType.boolean,
            value: 'oilSensorPM'
        },
        {
            text: i18n.t('mapReport.HEADERS.PM_ACTUATOR', { lang }),
            align: 'left',
            filterType: FilterType.multiSelect,
            options: headersOptions.boolean,
            selected: true,
            sortable: true,
            type: DataType.boolean,
            value: 'actuatorPM'
        },
        {
            text: i18n.t('mapReport.HEADERS.PM_BATTERY_TENSION', { lang }),
            align: 'right',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'batteryTensionPM'
        },
        {
            text: i18n.t('mapReport.HEADERS.PM_TEMPERATURE', { lang }),
            align: 'left',
            filterType: FilterType.textField,
            selected: true,
            sortable: true,
            type: DataType.number,
            value: 'temperaturePM'
        }
    ]
  }

  export default { headers }
