import { mapGetters, mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'

export default {
  name: 'SnackbarComponent',
  data () {
    return {
      visible: false,
      text: '',
      timeout: -1,
      multiline: false,
      style: SnackbarStyle.ERROR,
      color: 'red',
      showCustomBtn: false
    }
  },
  computed: {
    ...mapGetters('snackbar', [
      'getVisible',
      'getText',
      'getTimeout',
      'getMultiline',
      'getColor',
      'getShowCustomBtn',
      'getCustomBtnIcon',
      'getCustomBtnTooltip',
      'getCustomBtnAction'
    ])
  },
  methods: {
    /**
     * actions del snackbar
     */
    ...mapActions('snackbar', [
      'closeSnackbar'
    ])
  },

  created: function () {
    this.$store.watch(state => state.snackbar.visible, () => {
      this.text = this.$store.state.snackbar.text
      this.visible = this.$store.state.snackbar.visible
      this.timeout = this.$store.state.snackbar.timeout > 0 ? this.$store.state.snackbar.timeout : -1
      this.multiline = this.$store.state.snackbar.multiline
      this.style = this.$store.state.snackbar.style
      this.showCustomBtn = this.$store.state.snackbar.showCustomBtn
      switch (this.style) {
        case SnackbarStyle.ERROR:
          this.color = 'error'
          break
        case SnackbarStyle.WARNING:
          this.color = 'warning'
          break
        case SnackbarStyle.SUCCESS:
          this.color = 'success'
          break
        case SnackbarStyle.INFO:
          this.color = 'info'
          break
        default:
          this.color = 'black'
          break
      }
    })
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  }
}
