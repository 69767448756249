import service from '@/middleware'

const appToolsApi = {

  async getAll () {
    return service.http.get(`/api/apptools`)
  },

  async search (search) {
    return service.http.get('/api/apptools', {
      params: {
        key: search
      }
    })
  }

}

export default appToolsApi
