import service from '@/middleware'

const businessTargetApi = {
  async getStateTargets() {
    const { data } = await service.http.get('/api/business-target/state-targets')
    return data
  },

  async getStateTargetsByThingsId(idThings) {
    const { data } = await service.http.post('/api/business-target/getStateTargetMapByIdThings', {idThings})
    return data
  },
}

export default businessTargetApi
