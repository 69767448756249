import service from '@/middleware'

const userApi = {
  async newUser (newUser, creatorUsername) {
    const body = { creatorUsername, newUser }
    const user = await service.http.post('api/user/create', body)
    return user
  },

  async updateAlertConfiguration (alertConfig) {
    return service.http.put('/api/user/alert-configuration', alertConfig)
  }
}

export default userApi
