import crayolasJson from '@/assets/crayolas'
import moment from 'moment'
import { TimeRanges, CustomTimeRangeTypes, TimeFormat } from '@colven/common-domain-lib/lib'
import jStat from 'jstat'
import i18n from '@/i18n'

/**
 * Método para seleccionar aleatoriamente los colores de los gráficos de torta/dona
 * @param {*} usedColors
 */
export const pickColor = function (usedColors) {
  let color = crayolasJson[Math.floor(Math.random() * crayolasJson.length)].hex
  if (usedColors && usedColors.length > 0) {
    let i = 0
    while (i < crayolasJson.length && usedColors.includes(color)) {
      color = crayolasJson[Math.floor(Math.random() * crayolasJson.length)].hex
      i++
    }
    return color
  } else {
    return color
  }
}
/**
 * Para comparar objetos
 * @param {*} o1
 * @param {*} o2
 */
export const objectsEqual = function (o1, o2) {
  if (!o1 && !o2) {
    return true
  } else {
    return typeof o1 === 'object' && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
      : o1 === o2
  }
}
/**
 * Para comparar arrays
 * @param {*} a1
 * @param {*} a2
 */
export const arraysEqual = function (a1, a2) {
  return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
}
/**
 * Función para mostrar un elemento html en pantalla completa
 * @param {*} elem
 */
export const toggleFullscreen = function (elem) {
  elem = elem || document.documentElement
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    }
    return 'fullscreen_exit'
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
    return 'fullscreen'
  }
}

/**
 * Función para eliminar hijos de una lista multinivel
 * @param {*} list
 * @param {*} children
 */
export const removeChildren = function (list, children) {
  children.forEach(child => {
    removeItem(list, child)
  })
}

/**
 * Función recursiva para eliminar un hijo de una lista multinivel
 * @param {*} list
 * @param {*} item
 */
export const removeItem = function (list, item) {
  list.forEach(object => {
    if (object.id === item.id) {
      return list.splice(list.indexOf(object), 1)
    } else if (object.children && object.children.length > 0) {
      removeItem(object.children, item)
    }
  })
}

/**
 * Función para agregar un hijo (o un arreglo de hijos) a una lista multinivel
 * @param {*} list
 * @param {*} item
 */
export const addToList = function (list, item) {
  if (item instanceof Array) {
    item.forEach(i => {
      addItemToList(list, i)
    })
  } else {
    addItemToList(list, item)
  }
}

/**
 * Función recursiva para agregar un hijo a una lista multinivel
 * @param {*} list
 * @param {*} item
 */
export const addItemToList = function (list, item) {
  if (!item.father) {
    list.push(item)
  } else {
    list.forEach(listItem => {
      if (listItem.id === item.father && listItem.children) {
        listItem.children.push(item)
      } else if (listItem.id !== item.father && listItem.children) {
        addItemToList(listItem.children, item)
      }
    })
  }
}

/**
 * Devuelve los nodos hoja de una lista andidada
 * @param {*} list
 * @param {*} result
 */
export const getChildrenFromList = function (list, result) {
  list.forEach(item => {
    if (item.children) {
      getChildrenFromList(item.children, result)
    } else {
      result.push(item)
    }
  })
}

/**
 * Búsqueda recursiva en una lista anidada
 * @param {*} list
 * @param {*} id
 */
export const findById = function (list, id) {
  for (let node of list) {
    if (node.id === id) return node

    if (node.children) {
      let desiredNode = findById(node.children, id)
      if (desiredNode) return desiredNode
    }
  }
  return false
}

/**
 * Devuelve los ids de los elementos de la lista (si tienen hijos, devuelve los id de los hijos)
 * @param {*} list
 * @param {*} result
 */
export const getIds = function (list, result) {
  list.forEach(item => {
    if (item.children && item.children.length > 0) {
      getIds(item.children, result)
    } else {
      result.push(item.id)
    }
  })
}
export const get_Ids = function (list, result) {
  list.forEach(item => {
    if (item.children && item.children.length > 0) {
      get_Ids(item.children, result)
    } else {
      result.push(item._id)
    }
  })
}
export const getItemsBy_Id = function (list, ids, result) {
  ids.forEach(id => {
    getItemBy_Id(list, id, result)
  })
}

export const getItemBy_Id = function (list, id, result) {
  list.forEach(item => {
    if (item._id === id) {
      result.push(item)
    } else if (item.children && item.children.length > 0) {
      getItemBy_Id(item.children, id, result)
    }
  })
}

export const getItemsById = function (list, ids, result) {
  ids.forEach(id => {
    getItemById(list, id, result)
  })
}

export const getItemById = function (list, id, result) {
  list.forEach(item => {
    if (item.id === id) {
      result.push(item)
    } else if (item.children && item.children.length > 0) {
      getItemById(item.children, id, result)
    }
  })
}

export const getDateTimeRange = function (selectedDateAndTimeRange, selectedDateAndTimeRangeType, sinceDate, sinceTime, toDate, toTime) {
  let tFrom
  let tTo
  const to = moment()
  const from = moment()
  let timezone = localStorage.getItem('timezone')
  timezone = timezone ? parseInt(timezone) : -(new Date().getTimezoneOffset() / 60)
  switch (selectedDateAndTimeRange) {
    case TimeRanges.LAST_HOUR:
      // lastHour
      tFrom = from.subtract(1, 'hours').unix()
      tTo = to.unix()
      break
    case TimeRanges.LAST_SIX_HOUR:
      // lastSixHours
      tFrom = from.subtract(6, 'hours').unix()
      tTo = to.unix()
      break
    case TimeRanges.TODAY:
      tFrom = moment.utc().startOf('day').subtract(timezone, 'hours').unix()
      tTo = to.unix()
      break
    case TimeRanges.YESTERDAY:
      tFrom = moment.utc().subtract(1, 'days').startOf('day').subtract(timezone, 'hours').unix()
      tTo = moment.utc().subtract(1, 'days').endOf('day').subtract(timezone, 'hours').unix()
      break
    case TimeRanges.LAST_WEEK:
      // lastWeek
      tFrom = from.subtract(1, 'weeks').unix()
      tTo = to.unix()
      break
    case TimeRanges.ENTERPRISE:
      // enterprise
      tFrom = getEnterpriseCustomTimeSpan()
      tTo = to.unix()
      break
    case TimeRanges.ENTERPRISE_PREVIOUS:
      // jornada anterior
      // El fin de la jornada anteriro es el mismo que el inicio de la jornada diaria
      tTo = getEnterpriseCustomTimeSpan()
      // El inicio de la jornada anterior es el mismo inicio que el de la jornada diaria restandole 24hs
      tFrom = moment.unix(tTo).subtract(1, 'days').unix()
      break
    case TimeRanges.HARVEST_START:
      // desde inicio de zafra
      tFrom = Number(JSON.parse(localStorage.getItem('harvestStartTime')).start)
      tTo = to.unix()
      break
    case TimeRanges.CUSTOM:
      // custom
      switch (selectedDateAndTimeRangeType) {
        case CustomTimeRangeTypes.DATE_AND_TIME:
          // date and time
          tTo = moment(toDate + ' ' + toTime, 'YYYY-MM-DD hh:mm').unix()
          tFrom = moment(sinceDate + ' ' + sinceTime, 'YYYY-MM-DD hh:mm').unix()
          break
        case CustomTimeRangeTypes.DATE:
          // only date
          tTo = moment(toDate, 'YYYY-MM-DD').unix()
          tFrom = moment(sinceDate, 'YYYY-MM-DD').unix()
          break
        case CustomTimeRangeTypes.TIME:
          // only time
          // TO DO: todavía no se implementó en el backend
          break
      }
      break
  }
  return { tFrom, tTo }
}

/**
 * Obtener el timestamp de inicio de la jornada diaria actual
 * @returns
 */
export const getEnterpriseCustomTimeSpan = () => {
  const enterpriseTimeSpan = JSON.parse(localStorage.getItem('enterpriseTimeSpan'))
  const customTimeSpan = enterpriseTimeSpan != null && enterpriseTimeSpan.start != null
    ? enterpriseTimeSpan.start
    : 0
  const now = moment.utc()
  const dateTo = moment.utc().set('minutes', 0).set('seconds', 0).set('milliseconds', 0)
  let from
  if (now.hour() < customTimeSpan) {
    from = dateTo.subtract(1, 'days').set('hours', Math.trunc(customTimeSpan)).unix()
  } else {
    from = dateTo.set('hours', Math.trunc(customTimeSpan)).unix()
  }
  return from
}

export const sortFunction = function (a, b) {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()
  if (nameA < nameB) {
    return -1
  } else if (nameA > nameB) {
    return 1
  } else {
    return 0
  }
}

export const dateSortFunction = function (a, b, format) {
  const dateA = moment(a, format)
  const dateB = moment(b, format)
  if (!dateA.isValid() && dateB.isValid()) {
    return -1
  } else if (dateA.isValid() && !dateB.isValid()) {
    return 1
  } else if (!dateA.isValid() && !dateB.isValid()) {
    return 0
  } else if (dateA.isBefore(dateB)) {
    return -1
  } else if (dateA.isAfter(dateB)) {
    return 1
  } else {
    return 0
  }
}

export const sortFunctionFloat = function (a = '0', b = '0', unit) {
  const nameA = parseFloat(a.toString().split(unit)[0])
  const nameB = parseFloat(b.toString().split(unit)[0])
  if (nameA < nameB) {
    return -1
  } else if (nameA > nameB) {
    return 1
  } else {
    return 0
  }
}

/**
 * Funciones para los cálculos al pie de la tabla
 * @param {*} numbers
 * @param {*} calc
 */
export const statisticFunction = function (numbers, id) {
  switch (id) {
    case 0:
      return jStat.sum(numbers)
    case 1:
      return jStat.mean(numbers)
    case 2:
      return jStat.median(numbers)
    case 3:
      return jStat.stdev(numbers)
    case 4:
      return jStat.variance(numbers)
    case 5:
      return jStat.min(numbers)
    default:
      console.error(`Calc ${id} not exists!`)
  }
}

/**
 * Función para validar el rango de fechas personalizado del selector, utiliza el valor del formulario
 * @param {*} selectedDateAndTimeRange
 * @param {*} selectedDateAndTimeRangeCustomType
 * @param {*} customDateTimeValidForm
 * @param {*} sinceDate
 * @param {*} sinceTime
 * @param {*} toDate
 * @param {*} toTime
 */
export const selectorDateTimeValidation = function (selectedDateAndTimeRange, selectedDateAndTimeRangeCustomType, customDateTimeValidForm, sinceDate, sinceTime, toDate, toTime) {
  return (selectedDateAndTimeRange === TimeRanges.CUSTOM && !customDateTimeValidForm) || (selectedDateAndTimeRange === TimeRanges.CUSTOM && dateAndTimeRangeEmptyFields(selectedDateAndTimeRangeCustomType, sinceDate, sinceTime, toDate, toTime, false))
}

export const dateAndTimeRangeEmptyFields = function (selectedDateAndTimeRangeCustomType, sinceDate, sinceTime, toDate, toTime, all) {
  switch (selectedDateAndTimeRangeCustomType) {
    case CustomTimeRangeTypes.DATE_AND_TIME:
      if (all) {
        return !toDate && !toTime && !sinceDate && !sinceTime
      } else {
        return !toDate || !toTime || !sinceDate || !sinceTime
      }
    case CustomTimeRangeTypes.DATE:
      if (all) {
        return !toDate && !sinceDate
      } else {
        return !toDate || !sinceDate
      }
    case CustomTimeRangeTypes.TIME:
      if (all) {
        return !toTime && !sinceTime
      } else {
        return !toTime || !sinceTime
      }
  }
}

/**
 * Convertir UNIX timestamp a ISODate
 * @param {*} unixTimestamp
 * @param {*} initZero
 * @returns
 */
export const ISODateFromUnixTimestamp = (unixTimestamp, initZero = false) => {
  const date = new Date(unixTimestamp * 1000)
  const dateString = date.toString() !== 'Invalid Date' ? date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString() : ''
  const timeStringParts = date.toLocaleTimeString().split(':')
  const timeString = timeStringParts[0] + ':' + timeStringParts[1]
  return {
    date: dateString,
    time: initZero ? timeString : ((timeString !== '0:00') ? timeString : '')
  }
}

/**
  * Obtener offset UTC
  * @returns
  */
export const getTimeOffset = () => {
  const timezone = localStorage.getItem('timezone')
  return timezone ? parseInt(timezone) : -(new Date().getTimezoneOffset() / 60)
}
const parseAndGetSecons = (value, number) => {
  return parseInt(value) * number
}

const parseAndGetSeconsByDay = (value, regex) => {
  const matchs = value.match(regex)
  if (!matchs) {
    return 0
  }
  if (matchs.length === 5) {
    return parseAndGetSecons(matchs[1], 86400) + parseAndGetSecons(matchs[2], 3600) + parseAndGetSecons(matchs[3], 60) + parseAndGetSecons(matchs[4], 1)
  }
  return parseAndGetSecons(matchs[1], 3600) + parseAndGetSecons(matchs[2], 60) + parseAndGetSecons(matchs[3], 1)
}

export const sortTimeByDay = (a, b, timeFormat) => {
  let regex = /(.*)d (.*)h (.*)m (.*)s/
  if (timeFormat.value === TimeFormat.HH_MM_SS.id) {
    regex = /(.*):(.*):(.*)/
  }
  const numberA = parseAndGetSeconsByDay(a, regex)
  const numberB = parseAndGetSeconsByDay(b, regex)
  if (!numberA && numberB) {
    return -1
  } else if (numberA && !numberB) {
    return 1
  } else if (!numberA && !numberB) {
    return 0
  } else if (numberA < numberB) {
    return -1
  } else if (numberA > numberB) {
    return 1
  } else {
    return 0
  }
}

/**
 * Mapa con las traducciones de los rangos de tiempo
 * @returns
 */
export const getTimeRangeNames = () => {
  const timeNames = {}
  timeNames[TimeRanges.HARVEST_START] = i18n.t('selector.dateAndTime.harvestStart')
  timeNames[TimeRanges.CUSTOM] = i18n.t('selector.dateAndTime.custom')
  timeNames[TimeRanges.LAST_HOUR] = i18n.t('selector.dateAndTime.lastHour')
  timeNames[TimeRanges.LAST_SIX_HOUR] = i18n.t('selector.dateAndTime.lastSixHours')
  timeNames[TimeRanges.TODAY] = i18n.t('selector.dateAndTime.today')
  timeNames[TimeRanges.YESTERDAY] = i18n.t('selector.dateAndTime.yesterday')
  timeNames[TimeRanges.LAST_WEEK] = i18n.t('selector.dateAndTime.lastWeek')
  timeNames[TimeRanges.ENTERPRISE] = i18n.t('selector.dateAndTime.dailyShift')
  timeNames[TimeRanges.ENTERPRISE_PREVIOUS] = i18n.t('selector.dateAndTime.previousShift')
  return timeNames
}

export const getThingsIdMapByArray = (things) => {
  const result = {};
  if (things && things.length > 0) {
    for (const thing of things) {
      result[thing.id] = thing;
    }
  }
  return result;
}

export const copyJson = (object) => {
  return JSON.parse(JSON.stringify(object));
}

export const parseDateByTimezone = (timestamp) => {
  const timezone = getTimeOffset();
  return moment(timestamp * 1000).utcOffset(timezone).format("DD/MM/YYYY HH:mm:ss");
}

export const goFullscreenById = (id) => {
  const elem = document.getElementById(id);
  if (!document.fullscreenElement && !document.mozFullScreenElement &&
    !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }
}