const state = {
  data: []
}

const getters = {
  getData: (state) => {
    return state.data
  }
}

const mutations = {
  updateDataTable: (state, data) => {
    state.data = data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
