export const state = () => ({
  firebaseToken: undefined,
  osData: undefined
})

const getters = {
  getFirebaseToken: (state) => {
    return state.firebaseToken
  },
  getOSData: (state) => {
    return state.osData
  }
}

const actions = { }

const mutations = {
  setFirebaseToken (state, payload) {
    state.firebaseToken = payload.firebaseToken
  },
  setOSData (state, payload) {
    state.osData = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
