export default function setup() {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Array.prototype, 'clean', {
		value: function () {
			this.splice(0, this.length)
		},
		enumerable: false
	});

	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Array.prototype, 'cleanAndUpdate', {
		value: function (newArray) {
			this.clean()
			this.pushArray(newArray)
		},
		enumerable: false
	});
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Array.prototype, 'pushArray', {
		value: function (newArray) {
			if (newArray) {
				newArray.forEach(element => {
					this.push(element)
				})
			}
		},
		enumerable: false
	});
}