import appToolsApi from '@/api/apptools.api.js'

export default {
  data: () => ({
    descriptionLimit: 60,
    tools: [],
    isLoading: false,
    model: null,
    search: null,
    width: null
  }),

  computed: {

    fields () {
      if (!this.model) return []

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a'
        }
      })
    },

    items () {
      if (!this.tools) return []

      return this.tools.map(tool => {
        const name = tool.name.length > this.descriptionLimit
          ? tool.name.slice(0, this.descriptionLimit) + '...'
          : tool.name

        return Object.assign({}, tool, { name })
      })
    }

  },

  mounted: function () {
    this.width = this.props.width || null
    this.$vuetify.lang.current = this.$i18n.locale
  },

  watch: {

    search (searchInput) {
      // Elementos ya pedidos
      if (this.isLoading) return

      this.isLoading = true

      // Pido elementos según búsqueda al api
      appToolsApi.search(searchInput)

        .then(tools => {
          this.tools = tools.data
        })

        .catch(err => {
          console.log('TOOL SEARCH ERROR: ', err)
        })

        .finally(() => (this.isLoading = false))
    }

  },

  props: {

    width: String

  }

}
