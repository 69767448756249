import service from '@/middleware';

class HarvestTargetApi {
    async getHarvestProgress(filters) {
        const { data } = await service.http.post("/api/harvestTarget/getHarvestProgress", filters);
        return data;
    }

    async getObjectiveProgressByState(activity, workFronts, states = [], from, to, enterpriseId) {
		return await service.http.post('/api/harvest-target/objective-harvest-state', { activity, workFronts, states, from, to, enterpriseId })
    }

    async getThingsProgressByActivity(things) {
      const { data } = await service.http.post('/api/harvestTarget/things-progress-by-activity', { things })
      return data
    }

    async getTendencyStatus(things) {
      const { data } = await service.http.post('/api/harvestTarget/work-by-day', { things })
      return data
    }

    async getTendencyStatusByHour(things, enterpriseId) {
      const { data } = await service.http.post('/api/harvestTarget/work-by-hour', { things, enterpriseId });
      return data;
    }

    async getHarvestConfigByHarvestName(harvestName) {
      const { data } = await service.http.post('/api/harvestTarget/harvest-config-by-enterprise-harvestName', { harvestName })
      return data
    }
}

export default new HarvestTargetApi();
