import service from '@/middleware'

const thingApi = {
  /**
   * Recurso para obtener el árbol de grupos -> equipos para los selectores
   * @returns
   */
  async getNested(sector = null) {
    const path = sector != null ? `/api/thing/all-nested?sector=${sector}` : '/api/thing/all-nested'
    const response = await service.http.get(path)
    return response
  },
  async getWorkFrontsNested(sectorKey = null) {
    let result = await service.http.get('/api/thing/work-front/all-nested', { params: { sectorKey } })
    return result
  },
  async getValidHarvestFrontsNested() {
    let result = await service.http.get('/api/thing/harvest-front/all-nested-valid')
    return result
  },
  async getHarvestFronts() {
    const result = await service.http.get('/api/thing/work-front/all')
    return result
  },
  async getHarvestFrontsByEnterprise() {
    const result = await service.http.get('/api/thing/harvestFront/allByEnterprise');
    return result
  },
  async getAllTrucks(sectorKey = null) {
    return service.http.get('/api/thing/trucks/all', { params: { sectorKey } })
  },
  async getAllTrucksNestedByGroupAndCategories() {
    return service.http.get('/api/thing/trucks/all-nested-group-categories')
  },
  async getAllThingsByType(typeKey) {
    return service.http.get(`/api/thing/${typeKey}`);
  },
  async getAllNestedByEnterprise() {
    let result = await service.http.get('/api/thing/enterprise/all-nested')
    return result
  },
  async getAllNestedByActivity() {
    let result = await service.http.get('/api/thing/activity/all-nested')
    return result
  },
  async getThingTypesByActivity(activities, sector = null, report = null) {
    let result = await service.http.post('/api/thing/types-by-activity', { activities, sector, report })
    return result
  },
  async getWorkFrontsByTypes(types, sectorKey = null, activity) {
    let result = await service.http.post('/api/thing/work-fronts/by-types', { types, sectorKey, activity })
    return result
  },
  async getWorkFrontsByTypeKeys(typeKeys, sectorKey = null) {
    let result = await service.http.post('/api/thing/work-fronts/by-type-keys', { typeKeys, sectorKey })
    return result
  },
  async getAllTypes() {
    let result = await service.http.get('/api/thing/types')
    return result
  },
  async getAllTypesToSelector() {
    let { data } = await service.http.get('/api/thing/types/allToSelector')
    return data;
  },
  async getByTypeId(id) {
    let result = await service.http.get(`/api/thing/type/${id}`)
    return result
  },
  /**
  * Obtener todas las cosas del tipo enviado como parámetro
  * @param key
  */
  async getThingByTypeKey(typeKey, sectorKey = null) {
    let result = await service.http.get(`/api/thing/${typeKey}`, { params: { sectorKey } })
    return result
  },
  /**
   * Obtener todos los íconos personalizados para el mapa
   * @returns
   */
  async getIcons() {
    const result = await service.http.get('/api/thing/icons')
    return result
  },
  /**
   * Obtener un arreglo con los íconos que cambiaron para el mapa
   * @returns
   */
  async getUpdatedIcons(idsAndVersions) {
    const result = await service.http.post('/api/thing/updated-icons', idsAndVersions)
    return result
  },

  async iconsMissed(icons) {
    const result = await service.http.post('/api/thing/iconsMissed', icons)
    return result.data
  },
  async getEnterpriseEventConfigurationMultipleTypes(types) {
    const result = await service.http.post('/api/thing/enterprise-event-configurations-multiple-types', { types })
    return result.data
  },
  async getActivitiesSectorsAndTypesNamesMapped(activityIds, sectorIds, typeKeys, trailerIds) {
    const result = await service.http.post('/api/thing/map-current-state-report-data', { activityIds, sectorIds, typeKeys, trailerIds })
    return result.data
  },
  async getStateTargetsByThingIds(thingsId) {
    const result = await service.http.post('/api/harvestTarget/getStateTargetMapByIdThings', { thingsId })
    return result.data
  },
  async getThingsState(thingIds) {
    const { data } = await service.http.get(`/api/thing/things-state?ids=${JSON.stringify(thingIds)}`)
    return data
  },
  async getAllThingsByIdsComplete(ids) {
    const result  = await service.http.post('/api/thing/getThingsByIds',{ids})
    return result.data
  }
  
}

export default thingApi
