/* eslint-disable indent */
import indexedDBService from '@/indexedDB/indexedDB.service'
import store from '@/store/store'
import moment from 'moment'
import { FirebaseMessageConfig, DialogType } from '@/constants/constants'
import i18n from '@/i18n'
import router from '@/router'

const AUTOREPORT_STORE = 'autoReports'

/**
 * Obtener un reporte de la DB a partir de su id
 * @param {*} reportId
 * @returns
 */
const getReportFromDB = async (reportId) => {
    try {
        const report = await indexedDBService.getOneById(AUTOREPORT_STORE, reportId)
        if (report) {
            store.dispatch('autoReport/setCurrentReportId', reportId)
        }
        return report
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * Guardar/actualizar un reporte en la base datos y en el store
 * @param {*} report
 */
const upsertReport = async (report) => {
    try {
        // guardo en la DB
        if (report.id == null) {
            delete report.id
        }
        const id = await indexedDBService.put(AUTOREPORT_STORE, report)
        // guardo en el store (para mostrar en la bandeja temporal de reportes)
        store.dispatch('autoReport/upsertReport',
            {
                id,
                route: report.route,
                title: report.title,
                name: report.name,
                icon: report.icon,
                color: report.color,
                lastGeneratedDate: report.lastGeneratedDate
            })
        console.info('Auto-report saved to IndexedDB')
        // cada vez que se genera un auto-reporte, significa que es el reporte que está actualmente en pantalla
        store.dispatch('autoReport/setCurrentReportId', id)
        return id
    } catch (error) {
        console.error(error)
    }
}

/**
 * Eliminar los reportes guardados en la base de datos y en el store
 */
const deleteReports = async () => {
    try {
        await indexedDBService.deleteAll(AUTOREPORT_STORE)
        store.dispatch('autoReport/setReports', [])
        console.info('All reports saved were deleted')
    } catch (error) {
        console.error(error)
    }
}

/**
 * Obtener el ícono del reporte a partir de la URL
 * @param {*} reportUrl
 * @returns
 */
const getReportIcon = (reportUrl) => {
    const menus = store.getters['auth/menu']
    let subMenu = null
    if (menus != null) {
        let i = 0
        let menu
        while (i < menus.length && subMenu == null) {
            menu = menus[i]
            while (subMenu == null) {
                subMenu = menu.subMenu.find(sm => sm.route === reportUrl)
            }
            i++
        }
    }
    return subMenu ? { icon: subMenu.icon, color: '#42A5F5' } : FirebaseMessageConfig.REPORT
}

/**
 * Guardar un reporte generado con auto-reporte en la base de datos indexada del navegador
 * @param {*} data
 * @param {*} filters
 * @param {*} route
 * @param {*} title
 * @param {*} name
 * @param {*} reportId
 * @returns
 */
const saveAutoReport = async (data, filters, route, title, name, reportId = null) => {
    const lastGeneratedDate = moment().format('DD/MM/YYYY HH:mm:ss')
    const { color, icon } = getReportIcon(route)
    const id = await upsertReport({
        id: reportId,
        data,
        filters,
        route,
        title,
        icon,
        color,
        name,
        lastGeneratedDate
    })
    return id
}

/**
 * Función para validar los parámetros de la URL
 * @param {*} queryParams
 * @returns
 */
const autoReportRouteQueryValidation = (queryParams) => {
    // reporte diferido -> parámetro key
    if (queryParams.key != null) {
        return { type: 'DEFERRED', key: queryParams.key }
    } else if (queryParams.type === 'AUTO_REPORT' && queryParams.autoReportId != null) {
        // auto reporte -> parámetro type es AUTO_REPORT y se pasa el id
        return { type: queryParams.type, autoReportId: parseInt(queryParams.autoReportId) }
    } else {
        // reporte vacío
        return { type: 'EMPTY' }
    }
}

/**
 * CONSULTA A LA BASE DE DATOS
 * Validar la existencia de un auto-reporte guardado con el nombre
 * Retorna true si existe un auto-reporte guardado con ese nombre
 * @param {*} name
 * @returns
 */
const validateAutoReportNameDB = async (name) => {
    const db = indexedDBService.getDB()
    return new Promise((resolve) => {
        const transaction = db.transaction([AUTOREPORT_STORE], 'readonly')
        transaction.oncomplete = () => {
            resolve(match)
        }

        const objectStore = transaction.objectStore(AUTOREPORT_STORE)
        const index = objectStore.index('nameIndex')
        const autoReport = index.get([name])

        let match = false
        autoReport.onsuccess = function (e) {
            if (e.target.result) {
                match = true
            }
        }
    })
}

/**
 * CONSULTA AL STORE
 * Validar la existencia de un auto-reporte guardado con el nombre
 * Retorna true si existe un auto-reporte guardado con ese nombre
 * @param {*} name
 * @returns
 */
const validateAutoReportName = (name) => {
    const reports = store.getters['autoReport/getReports']
    const report = reports.find(r => r.name === name)
    return report != null
}

/**
 * Eliminar todos los reportes guardados en la base de datos del browser
 */
const deleteAll = (route) => {
    const currentReportId = store.getters['autoReport/getCurrentReportId']
    store.dispatch('dialog/openDialog', {
        title: i18n.t('temporaryReportTray.deleteAllTitle'),
        text: i18n.t('temporaryReportTray.deleteAllMessage'),
        type: DialogType.QUESTION,
        overlayOpacity: 0.5,
        actionButtons: true,
        yesAction: async () => {
            indexedDBService.deleteAll(AUTOREPORT_STORE).then(() => {
                store.dispatch('autoReport/setReports', [])
                // si hay un reporte generándose actualmente, se recarga la página
                if (currentReportId) {
                    store.dispatch('autoReport/setCurrentReportId', null)
                    router.push({ path: route })
                        .catch(() => {
                            location.reload()
                            console.info('Page was reloaded')
                        })
                }
            })
        },
        noAction: () => {
        }
    })
}

/**
 * Eliminar un reporte guardado en la base de datos del browser
 * @param {*} id
 */
const deleteOne = (id, name, route) => {
    const currentReportId = store.getters['autoReport/getCurrentReportId']
    store.dispatch('dialog/openDialog', {
        title: i18n.t('temporaryReportTray.deleteOneTitle'),
        text: i18n.t('temporaryReportTray.deleteOneMessage', { name }),
        type: DialogType.QUESTION,
        overlayOpacity: 0.5,
        actionButtons: true,
        yesAction: async () => {
            indexedDBService.deleteOneById(AUTOREPORT_STORE, id).then(() => {
                store.dispatch('autoReport/deleteReport', id)
                // si el reporte actual es el que se está eliminando recargo la página del reporte
                if (currentReportId === id) {
                    store.dispatch('autoReport/setCurrentReportId', null)
                    router.push({ path: route })
                        .catch(() => {
                            location.reload()
                            console.info('Page was reloaded')
                        })
                }
            })
        },
        noAction: () => {
        }
    })
}

/**
 * Actualizar los query params con el id del auto-reporte si es necesario
 * @param {*} currentQueryParams
 * @param {*} autoReportId
 */
const updateQueryParams = (route, currentQueryParams, autoReportId) => {
    if (currentQueryParams == null || (currentQueryParams != null && (currentQueryParams.type !== 'AUTO_REPORT' || currentQueryParams.autoReportId !== autoReportId))) {
        history.pushState({}, null, `${route}?type=AUTO_REPORT&autoReportId=${autoReportId}`)
    }
}

export default {
    getReportFromDB,
    upsertReport,
    deleteReports,
    saveAutoReport,
    autoReportRouteQueryValidation,
    validateAutoReportNameDB,
    validateAutoReportName,
    deleteAll,
    deleteOne,
    updateQueryParams
}
