import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import axios from 'axios'
import mixins from './mixin/mixins'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import CountryFlag from 'vue-country-flag'
import setup from './tools/array'
import moment from 'moment'
import splitPane from 'vue-splitpane'
import '@colven/common-components'
import '@colven/common-components/dist/gestyaCommonComponents.css'
// service worker
import wb from './registerServiceWorker'
import VueThermometer from 'vuejs-thermometer'

Vue.prototype.$workbox = wb
Vue.use(VueThermometer)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.moment = moment
Vue.component('country-flag', CountryFlag)
Vue.component('split-pane', splitPane)
setup()
mixins()

// global event bus
Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
