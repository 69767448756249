export const navBarService = (function () {
  'use strict'
  const analiticItems = {
    id: 'toolbar_mapsBtn',
    title: 'Analisis',
    subMenu: [
      {
        title: 'Mapa 2.0',
        icon: 'dashboard',
        route: '/mapMonitor'
      },
      {
        title: 'Mapa Analitico',
        icon: 'dashboard',
        route: '/mapHistory'
      },
      {
        title: 'Canchon Mapa',
        icon: 'dashboard',
        route: '/harvestFrontsMap'
      },
      {
        title: 'Canchon Analitico',
        icon: 'dashboard',
        route: '/HistoricTruckDispatcher'
      },
      {
        title: 'Canchon Analitico Mapa',
        icon: 'dashboard',
        route: '/HistoricTruckDispatcherMap'
      },
    ]
  }
  const navBarItems = [
    {
      id: 'toolbar_workingAreaBtn',
      title: 'navbar.categories.areastrabajo.title',
      // route: '/work-area',
      keys: { and: ['work.area'] },
      subMenu: [
        {
          title: 'navbar.dashboard',
          icon: 'dashboard',
          route: '/work-area/dashboard',
          keys: { and: ['work.area.dashboard'] }
        },
        {
          title: 'navbar.categories.areastrabajo.submenu.areastrabajadas.title',
          icon: 'list_alt',
          route: '/work-area/summary-report',
          keys: { and: ['work.area.summary.report'] }
        }
      ]
    },
    {
      id: 'toolbar_field_engineering',
      title: 'navbar.categories.ingcampo.title',
      // route: '/field-engineering',
      keys: { and: ['field.engineering'] },
      route: '',
      subMenu: [
        {
          title: 'navbar.dashboard',
          icon: 'dashboard',
          route: '/field-engineering/dashboard',
          keys: { and: ['field.engineering.dashboard'] }
        },
        {
          title: 'navbar.categories.common.estadomaquina.title',
          icon: 'list_alt',
          route: '/field-engineering/current-status',
          keys: { and: ['field.engineering.current.state'] }
        },
        {
          title: 'navbar.categories.common.resumenmaquina.title',
          icon: 'list_alt',
          route: '/field-engineering/summary-report',
          keys: { and: ['field.engineering.summary.report'] }
        },
        {
          title: 'navbar.categories.frentes.submenu.ubicacionfrentes.title',
          icon: 'list_alt',
          route: '/harvest-fronts/location-report',
          keys: { and: ['field.engineering.harvest.front.location.resport'] }
        }
      ]
    },
    {
      id: 'toolbar_farm',
      title: 'navbar.categories.finca.title',
      keys: { and: ['farm'] },
      subMenu: [
        {
          title: 'navbar.dashboard',
          icon: 'dashboard',
          route: '/farm/dashboard',
          keys: { and: ['farm.dashboard'] }
        },
        {
          title: 'navbar.categories.common.estadomaquina.title',
          icon: 'list_alt',
          route: '/farm/current-status',
          keys: { and: ['farm.current.state'] }
        },
        {
          title: 'navbar.categories.common.resumenmaquina.title',
          icon: 'list_alt',
          route: '/farm/summary-report',
          keys: { and: ['farm.summary.report'] }
        }
      ]
    },
    {
      id: 'toolbar_harvest',
      title: 'navbar.categories.cosecha.title',
      keys: { and: ['harvest'] },
      // route: '/harvest',
      subMenu: [
        {
          title: 'navbar.dashboard',
          icon: 'dashboard',
          route: '/harvest/dashboard',
          keys: { and: ['harvest.dashboard'] }
        },
        {
          title: 'navbar.categories.common.estadomaquina.title',
          icon: 'list_alt',
          route: '/harvest/current-status',
          keys: { and: ['harvest.current.state'] }
        },
        {
          title: 'navbar.categories.common.resumenmaquina.title',
          icon: 'list_alt',
          route: '/harvest/summary-report',
          keys: { and: ['harvest.summary.report'] }
        }
      ]
    },
    {
      id: 'toolbar_fronts',
      title: 'navbar.categories.frentes.title',
      keys: { and: ['harvest.front'] },
      subMenu: [
        {
          title: 'navbar.categories.frentes.submenu.ubicacionfrentes.title',
          icon: 'list_alt',
          route: '/harvest-fronts/location-report',
          keys: { and: ['harvest.front.location.resport'] }
        }
      ]
    },
    {
      id: 'toolbar_transport',
      title: 'navbar.categories.transporte.title',
      // route: '/trucks',
      keys: { and: ['truck'] },
      subMenu: [
        {
          title: 'navbar.dashboard',
          icon: 'dashboard',
          route: '/trucks/dashboard',
          keys: { and: ['truck.dashboard'] }
        },
        {
          title: 'navbar.categories.transporte.submenu.estadocamiones.title',
          icon: 'list_alt',
          route: '/trucks/current-status-report',
          keys: { and: ['truck.current.state'] }
        },
        {
          title: 'navbar.categories.transporte.submenu.estadocamiones.title',
          icon: 'list_alt',
          route: '/dashboard/current-status',
          keys: { and: ['dashboard.truck.current.state'] }
        },
        {
          title: 'navbar.categories.transporte.submenu.resumencamiones.title',
          icon: 'list_alt',
          route: '/trucks/summary-report',
          keys: { and: ['truck.summary.resport'] }
        },
        {
          title: 'navbar.categories.transporte.submenu.historicocamiones.title',
          icon: 'list_alt',
          route: '/trucks/historical-report',
          keys: { and: ['truck.historical.report'] }
        },
        {
          title: 'navbar.categories.transporte.submenu.transporterrhh.title',
          icon: 'list_alt',
          route: '/trucks/rrhh?tab=2',
          keys: { and: ['truck.rrhh'] }
        }
      ]
    }, {
      id: 'toolbar_trackingMapBtn',
      title: 'navbar.categories.tracking.trackingMap.title',
      // route: '/work-area',
      keys: { and: ['work.area'] },
      subMenu: [
        {
          title: 'navbar.trackingMap',
          icon: 'map',
          route: '/tracking-map',
          keys: { and: ['work.area.dashboard'] }
        }
      ]
    },
    {
      id: 'toolbar_traceabilityBtn',
      title: 'navbar.categories.traceability.title',
      // route: '/work-area',
      keys: { and: ['traceability'] },
      subMenu: [
        {
          title: 'navbar.categories.traceability.submenu.CDCReport',
          icon: 'list_alt',
          route: '/traceability/CDC-report',
          keys: { and: ['traceability.cdc.report'] }
        },
        {
          title: 'navbar.categories.common.cdp.title',
          icon: 'list_alt',
          route: '/traceability/cdp-report',
          keys: { and: ['traceability.cdp.report'] }
        }
      ]
    },
    {
      id: 'toolbar_smartTruckDispatcherBtn',
      title: 'navbar.smartTruckDispatcher.menu',
      keys: { and: ['dashboard.smartTruckDispatcher'] },
      subMenu: [{
        title: 'navbar.smartTruckDispatcher.smartDispatch',
        icon: 'list_alt',
        route: '/SmartTruckDispatcher',
        keys: { and: ['dashboard.smartTruckDispatcher'] }
      }
      ]
    },
  ]

  function getMenuByUser() {
    // navBarItems
    let permissions = getPermissions()
    let admin = JSON.parse(localStorage.getItem('admin'))
    let navBarItemsTemp = JSON.parse(localStorage.getItem('menu')) || navBarItems;
    navBarItemsTemp = enableAnaliticItems(navBarItemsTemp);
    let result = []
    navBarItemsTemp.forEach((navBarItem) => {
      if (admin || validatePermissions(permissions, navBarItem)) {
        if (navBarItem.subMenu) {
          const items = []
          const mainMenu = Object.assign({}, navBarItem)
          navBarItem.subMenu.forEach((subMenuItem) => {
            if (admin || validatePermissions(permissions, subMenuItem)) {
              items.push(subMenuItem)
            }
          })
          mainMenu.subMenu = items
          result.push(mainMenu)
        } else {
          result.push(navBarItem)
        }
      }
    })
    return result
  }

  function enableAnaliticItems(items) {
    let result = items;
    if (localStorage.getItem("mapLog")) {
      result = result.concat(analiticItems);
    }
    return result;
  }

  function validatePermissions(permissions, item) {
    if (item.keys) {
      if (item.keys.and) {
        const conditions = item.keys.and
        let result = true
        conditions.forEach((condition) => {
          if (!permissions.includes(condition)) {
            result = result && false
          }
        })
        return result
      } else if (item.keys.or) {
        const conditions = item.keys.or
        let result = false
        conditions.forEach((condition) => {
          if (permissions.includes(condition)) {
            result = result || true
          }
        })
        return result
      }
      return false
    }
    return true
  }

  function getPermissions() {
    return localStorage.getItem('privileges') ? JSON.parse(localStorage.getItem('privileges')) : []
  }

  return {
    getMenuByUser
  }
})()
