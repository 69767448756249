/* eslint-disable indent */
import emailApi from '@/api/email.api'

/**
 * Servicio para reportar bugs
 */
export const reportBugService = (function () {
    'use strict'

    /**
     * Reportar un bug por email
     * @param {*} reportBug
     */
    async function sendEmail (reportBug) {
        const response = await emailApi.sendReportBugEmail(reportBug)
        return response.data
    }

    return {
        sendEmail
    }
})()
