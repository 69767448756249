/* eslint-disable indent */

const state = {
    showDialog: false
}

// getters
const getters = {
    getShowDialog: (state) => {
        return state.showDialog
    }
}

// actions
const actions = {
    setShowDialog ({ commit }, showDialog) {
        commit('commitShowDialog', { showDialog })
    },
    openChangeEnterpriseDialog ({ commit }) {
        commit('commitShowDialog', { showDialog: true })
    },
    closeChangeEnterpriseDialog ({ commit }) {
        commit('commitShowDialog', { showDialog: false })
    }
}

// mutations
const mutations = {
    commitShowDialog (state, { showDialog }) {
        state.showDialog = showDialog
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
