/* eslint-disable indent */
import autoReportService from '@/business/autoReportService'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { clone } from 'ramda'

/**
 * Bandeja temporal de reportes
 */
export default {
    name: 'TemporaryReportTrayComponent',
    components: {
    },
    data: () => ({
    }),
    computed: {
        /**
         * getters del store autoReport
         */
        ...mapGetters({
            'showTemporaryReportTray': 'autoReport/getShowTemporaryReportTray',
            'reports': 'autoReport/getReports',
            'currentId': 'autoReport/getCurrentReportId'
        }),
        /**
         * Reportes ordenados por fecha de generación
         * @returns
         */
        reportsSortedByDate () {
            const reportsCopy = clone(this.reports)
            return reportsCopy.sort((a, b) => {
                const dateA = moment(a.lastGeneratedDate, 'DD/MM/YYYY HH:mm:ss')
                const dateB = moment(b.lastGeneratedDate, 'DD/MM/YYYY HH:mm:ss')
                if (dateA.isBefore(dateB)) {
                    return 1
                } else if (dateA.isAfter(dateB)) {
                    return -1
                } else {
                    return 0
                }
            })
        }
    },
    mounted () {
    },
    methods: {
        // actions del store autoReport
        ...mapActions({
            'closeTemporaryReportTray': 'autoReport/closeTemporaryReportTray'
        }),
        /**
         * Click en el report -> ir al reporte con los datos
         * @param {*} report
         */
        reportClicked (report) {
            this.$router.push({
                path: report.route,
                query: { type: 'AUTO_REPORT', autoReportId: report.id } })
                .catch(() => {})
            this.closeTemporaryReportTray()
        },
        /**
         * Elimina todos los reportes guardados y, si la página actual corresponde a un reporte
         * que se está auto-generando, se recarga la página
         */
        deleteAllReports () {
            autoReportService.deleteAll(this.$route.path)
        },
        /**
         * Elimina un reporte guardado y, si la página actual corresponde al reporte,
         * se recarga la página
         * @param {*} reportId
         * @param {*} reportName
         */
        deleteOneReport (reportId, reportName) {
            autoReportService.deleteOne(reportId, reportName, this.$route.path)
        },
        getIcon (report) {
            const menu = JSON.parse(localStorage.getItem('menu'))
            let subMenu = null
            let icon = null
            if (menu) {
              menu.forEach(m => {
                subMenu = m.subMenu.find(sm => sm.route === report.route) ? m.subMenu.find(sm => sm.route === report.route) : subMenu
              })
              icon = subMenu ? subMenu.icon : null
            }
            if (!icon) {
              switch (report.route) {
                case '/dashboard/home':
                  icon = 'dashboard'
                  break
                case '/dashboard/alert-dashboard':
                  icon = 'mdi-alert'
                  break
                case '/dashboard/engine-state-dashboard':
                  icon = 'mdi-engine'
                  break
                case '/dashboard/harvester':
                  icon = 'spa'
                  break
                case '/dashboard/truck':
                  icon = 'commute'
                  break
                case '/dashboard/map':
                  icon = 'map'
                  break
                case '/dashboard/position':
                  icon = 'location_on'
                  break
                case '/dashboard/running-hours':
                  icon = 'timer'
                  break
                case '/dashboard/real-speed':
                  icon = 'speed'
                  break
                case '/dashboard/current-status':
                  icon = 'departure_board'
                  break
                case '/dashboard/machine-summary':
                  icon = 'mdi-tractor'
                  break
                case '/dashboard/on-board-monitor':
                  icon = 'tablet_mac'
                  break
                case '/dashboard/objective-tendency':
                  icon = 'tablet_mac'
                  break
                default:
                  icon = report.icon
              }
              return icon
            } else {
              return icon
            }
        },
        /**
         * Obtener el color para la fecha de generación del reporte
         * @param {*} date
         * @returns
         */
        getDateColor (date) {
            const nowSubstract10 = moment().subtract(10, 'minutes')
            const nowSubstract60 = moment().subtract(60, 'minutes')
            const momentTimestamp = moment(date, 'DD/MM/YYYY HH:mm:ss')
            if (momentTimestamp.isSameOrAfter(nowSubstract10)) {
                return '#66BB6A'
            } else if (momentTimestamp.isSameOrAfter(nowSubstract60) &&
                momentTimestamp.isBefore(nowSubstract10)) {
                return '#d6c105'
            } else if (momentTimestamp.isBefore(nowSubstract60)) {
                return '#ef5350'
            } else {
                return undefined
            }
        }
    }
}
