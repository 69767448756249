const state = {
  username: localStorage.getItem('username'),
  password: '',
  email: localStorage.getItem('email'),
  jwt: null,
  resetPasswordJwt: null,
  remember: false,
  locale: '',
  id: localStorage.getItem('userId'),
  admin: false,
  logo: localStorage.getItem('logo'),
  loginLogo: localStorage.getItem('loginLogo'),
  loginBackgroundImage: localStorage.getItem('loginBackgroundImage'),
  themes: JSON.parse(localStorage.getItem('themes')),
  privileges: [],
  locales: localStorage.getItem('locales') != null
    ? JSON.parse(localStorage.getItem('locales'))
    : null,
  menu: localStorage.getItem('menu') != null
    ? JSON.parse(localStorage.getItem('menu'))
    : null
}

// getters
const getters = {
  getId: (state) => {
    return state.id
  },
  getUsername: (state) => {
    return state.username
  },
  getPassword: (state) => {
    return state.password
  },
  getEmail: (state) => {
    return state.email
  },
  getJwt: (state) => {
    return state.jwt
  },
  getResetPasswordJwt: (state) => {
    return state.resetPasswordJwt
  },
  getRemember: (state) => {
    return state.remember
  },
  getLocale: (state) => {
    return state.locale
  },
  getLogo: (state) => {
    return state.logo
  },
  getLoginLogo: (state) => {
    return state.loginLogo
  },
  getLoginBackgroundImage: (state) => {
    return state.loginBackgroundImage
  },
  getThemes: (state) => {
    return state.themes
  },
  getToken: () => {
    return state.token || localStorage.getItem('token')
  },
  getPrivileges: state => {
    return (state.privileges.length > 0 && state.privileges) || JSON.parse(localStorage.getItem('privileges'))
  },
  getAdmin: state => {
    return state.admin || JSON.parse(localStorage.getItem('admin'))
  },
  getLocales: state => {
    return state.locales != null
      ? state.locales
      : (localStorage.getItem('locales') != null ? JSON.parse(localStorage.getItem('locales')) : null)
  },
  getMenu: state => {
    return state.menu
  }
}

// actions
const actions = {
  clearSessionData ({ commit }) {
    commit('commitId', { id: null })
    commit('commitEmail', { email: '' })
    commit('commitPassword', { password: '' })
    commit('commitToken', { token: null })
    commit('commitJwt', { jwt: '' })
    commit('commitAdmin', { admin: false })
    commit('commitPrivileges', { privileges: [] })
    commit('commitLocales', { locales: null })
    commit('commitMenu', { menu: null })

    localStorage.removeItem('userId')
    localStorage.removeItem('userConfiguration')
    localStorage.removeItem('email')
    localStorage.removeItem('token')
    localStorage.removeItem('admin')
    localStorage.removeItem('privileges')
    localStorage.removeItem('locales')
    localStorage.removeItem('menu')
    localStorage.removeItem('enterpriseTimeSpan')
    localStorage.removeItem('enterpriseId')
    localStorage.removeItem('harvestStartTime')
    localStorage.removeItem('mapPositionZoom')
  },
  setId ({ commit }, id) {
    if (id) {
      localStorage.setItem('userId', id)
    } else {
      localStorage.removeItem('userId')
    }
    commit('commitId', { id })
  },
  setUsername ({ commit }, username) {
    if (username) {
      localStorage.setItem('username', username)
    } else {
      localStorage.removeItem('username')
    }
    commit('commitUsername', { username })
  },
  setPassword ({ commit }, password) {
    commit('commitPassword', { password })
  },
  setEmail ({ commit }, email) {
    if (email) {
      localStorage.setItem('email', email)
    } else {
      localStorage.removeItem('email')
    }
    commit('commitEmail', { email })
  },
  setJwt ({ commit }, jwt) {
    commit('commitJwt', { jwt })
  },
  setResetPasswordJwt ({ commit }, resetPasswordJwt) {
    commit('commitResetPasswordJwt', { resetPasswordJwt })
  },
  setRemember ({ commit }, remember) {
    localStorage.setItem('remember', remember)
    commit('commitRemember', { remember })
  },
  setLocale ({ commit }, locale) {
    commit('commitLocale', { locale })
  },
  setLogo ({ commit }, logo) {
    if (logo) {
      localStorage.setItem('logo', logo)
    }
    commit('commitLogo', { logo })
  },
  setLoginLogo ({ commit }, loginLogo) {
    if (loginLogo) {
      localStorage.setItem('loginLogo', loginLogo)
    }
    commit('commitLoginLogo', { loginLogo })
  },
  setLoginBackgroundImage ({ commit }, loginBackgroundImage) {
    if (loginBackgroundImage) {
      localStorage.setItem('loginBackgroundImage', loginBackgroundImage)
    }
    commit('commitLoginBackgroundImage', { loginBackgroundImage })
  },
  setThemes ({ commit }, themes) {
    if (themes) {
      localStorage.setItem('themes', JSON.stringify(themes))
    }
    commit('commitThemes', { themes })
  },
  setPrivileges ({ commit }, privileges) {
    commit('commitPrivileges', { privileges })
  },
  setAdmin ({ commit }, admin) {
    commit('commitAdmin', { admin })
  },
  setToken ({ commit }, token) {
    commit('commitToken', { token })
  },
  setLocales ({ commit }, locales) {
    commit('commitLocales', { locales })
    if (locales != null) {
      localStorage.setItem('locales', JSON.stringify(locales))
    } else {
      localStorage.removeItem('locales')
    }
  },
  setMenu ({ commit }, menu) {
    if (menu == null || (Array.isArray(menu) && menu.length === 0)) {
      commit('commitMenu', { menu: null })
      localStorage.removeItem('menu')
    } else {
      commit('commitMenu', { menu })
      localStorage.setItem('menu', JSON.stringify(menu))
    }
  }
}

// mutations
const mutations = {
  commitId (state, { id }) {
    state.id = id
  },
  commitUsername (state, { username }) {
    state.username = username
  },
  commitPassword (state, { password }) {
    state.password = password
  },
  commitEmail (state, { email }) {
    state.email = email
  },
  commitJwt (state, { jwt }) {
    state.jwt = jwt
  },
  commitResetPasswordJwt (state, { resetPasswordJwt }) {
    state.resetPasswordJwt = resetPasswordJwt
  },
  commitRemember (state, { remember }) {
    state.remember = remember
  },
  commitLocale (state, { locale }) {
    state.locale = locale
  },
  commitLogo (state, { logo }) {
    state.logo = logo
  },
  commitLoginLogo (state, { loginLogo }) {
    state.loginLogo = loginLogo
  },
  commitLoginBackgroundImage (state, { loginBackgroundImage }) {
    state.loginBackgroundImage = loginBackgroundImage
  },
  commitThemes (state, { themes }) {
    state.themes = themes
  },
  commitToken (state, { token }) {
    state.token = token
    localStorage.setItem('token', token)
  },
  commitPrivileges (state, { privileges }) {
    state.privileges = privileges
    localStorage.setItem('privileges', JSON.stringify(privileges))
  },
  commitAdmin (state, { admin }) {
    state.admin = admin
    localStorage.setItem('admin', admin)
  },
  commitLocales (state, { locales }) {
    state.locales = locales
  },
  commitMenu (state, { menu }) {
    state.menu = menu
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
