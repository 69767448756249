import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.goTo(item.route)}}},[_c(VListItemAction,{staticClass:"mr-3"},[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(item.title)))])],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }