import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
// Translation provided by Vuetify (javascript)
import zhHans from 'vuetify/es5/locale/zh-Hans'
// Translation provided by Vuetify (typescript)
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'
import pt from 'vuetify/es5/locale/pt'
/**
 * Themes
 * Para cambiar del modo 'light' al modo 'dark':
 * this.$vuetify.theme.dark = true // this.$vuetify.theme.dark = false
 */
import { ColvenTheme } from '@/constants/constants'
let themesLocalStorage = JSON.parse(localStorage.getItem('themes'))
const darkLocalStorage = localStorage.getItem('dark') === 'true'
if (!themesLocalStorage) {
  themesLocalStorage = { light: ColvenTheme.light, dark: ColvenTheme.dark }
}

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

export default new Vuetify({
  options: {
    customProperties: true
  },
  icons: {
    iconfont: 'fa'
  },
  lang: {
    locales: { zhHans, en, es, pt },
    current: 'es'
  },
  theme: {
    dark: darkLocalStorage,
    themes: {
      light: themesLocalStorage.light,
      dark: themesLocalStorage.dark
    }
  }
})
