import service from '@/middleware'
import { NotificationType } from '@colven/common-domain-lib/lib'

/*
notification = {
  _id: '2k21jh421kj4218'
  userId: 'lgkju6o589ukjh',
  title: 'TITULO',
  text: 'TEXTO',
  type: 'REPORT',
  url: '/harvesters/current-status-report',
  key: 'djhdgaskjhwg2r3i7ur',
  show: false,
  delete: false,
  date: new Date()
*/
// mock de alertas
export const alertsMock = [
  {
    _id: 'alert1',
    userId: 'userId',
    title: 'Unidad de manjeo con héctareas pendientes: 101405',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-07-06T12:55:11.754Z',
    type: 'ALERT_DRIVE_UNIT_PENDING_HECTARES',
    url: '',
    show: true,
    delete: false
  },
  {
    _id: 'alert2',
    userId: 'userId',
    title: 'Unidad de manjeo con héctareas pendientes: 101404',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-06-25T11:51:37.767Z',
    type: 'ALERT_DRIVE_UNIT_PENDING_HECTARES',
    url: '',
    show: true,
    delete: false
  },
  {
    _id: 'alert3',
    userId: 'userId',
    title: 'Unidad de manejo por finalizar: 110104',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-06-24T17:58:56.276Z',
    type: 'ALERT_DRIVE_UNIT_TO_FISNISH',
    url: '',
    show: true,
    delete: false
  },
  {
    _id: 'alert4',
    userId: 'userId',
    title: 'Unidad de manejo por finalizar: 110105',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-06-23T19:50:04.811Z',
    type: 'ALERT_DRIVE_UNIT_TO_FISNISH',
    url: '',
    show: true,
    delete: false
  },
  {
    _id: 'alert5',
    userId: 'userId',
    title: 'Exceso de velocidad: cose ccpm 01',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-06-23T19:42:19.986Z',
    type: 'ALERT_SPEEDING',
    url: '',
    show: true,
    delete: false
  },
  {
    _id: 'alert6',
    userId: 'userId',
    title: 'Exceso de velocidad: cose ccpm 04',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-06-23T19:33:56.136Z',
    type: 'ALERT_SPEEDING',
    url: '',
    show: true,
    delete: false
  },
  {
    _id: 'alert7',
    userId: 'userId',
    title: 'Fuera de rango de velocidad: cose ccpm 01',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-06-23T19:13:48.204Z',
    type: 'ALERT_OUT_OF_SPEED_RANGE',
    url: '',
    show: true,
    delete: false
  },
  {
    _id: 'alert6',
    userId: 'userId',
    title: 'Fuera de rango de velocidad: cose ccpm 04',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: '2020-06-23T19:10:14.933Z',
    type: 'ALERT_OUT_OF_SPEED_RANGE',
    url: '',
    show: true,
    delete: false
  }
]
export const alertsBadgeMock = 8
export const alertsTotalMock = 8

const messagingApi = {
  // NOTIFICACIONES -----------------------------------------------------------------------------------------------------------------
  async findAll (pageSize, pageNumber, userId) {
    // Notificaciones paginadas
    const result = await service.http.get(`/api/messaging/findAll?count=${pageSize}&page=${pageNumber}&userId=${userId}`)
    return result
  },
  async findAllForType (pageSize, pageNumber, userId) {
    // Notificaciones paginadas
    const result = await service.http.get(`/api/messaging/findAllForType?count=${pageSize}&page=${pageNumber}&userId=${userId}&type=${NotificationType.REPORT.name}`)
    return result
  },
  async update (notification) {
    // Actualizar (marcar como leida/no-leida, eliminar)
    const result = await service.http.put(`/api/messaging/update`, notification)
    return result
  },
  async readAll (userId) {
    // Leer todas las notificaciones
    const result = await service.http.put(`/api/messaging/readAll?userId=${userId}`, [NotificationType.REPORT.name])
    return result
  },
  async deleteAll (userId) {
    // Eliminar todas las notificaciones
    const result = await service.http.put(`/api/messaging/deleteAll?userId=${userId}`, [NotificationType.REPORT.name])
    return result
  },
  async notReadCount (userId) {
    // La cantidad de notificaciones no leídas
    const result = await service.http.get(`/api/messaging/getBadge?userId=${userId}`)
    return result
  },
  async notReadCountForType (userId) {
    // La cantidad de notificaciones no leídas
    const result = await service.http.get(`/api/messaging/getBadgeForType?userId=${userId}&type=${NotificationType.REPORT.name}`)
    return result
  },
  async totalCount (userId) {
    let result = null
    if (userId) {
      result = await service.http.get(`/api/messaging/count?userId=${userId}`)
    }
    return result
  },
  async totalCountForType (userId) {
    let result = null
    if (userId) {
      result = await service.http.get(`/api/messaging/countForType?userId=${userId}&type=${NotificationType.REPORT.name}`)
    }
    return result
  },
  // ALERTAS -----------------------------------------------------------------------------------------------------------------
  async findAllAlerts (pageSize, pageNumber, userId) {
    // alertas paginadas
    const result = await service.http.post('/api/messaging/findAllForTypes',
      { count: pageSize, page: pageNumber, userId, types: [NotificationType.REMINDER.name, NotificationType.ALERT.name] })
    return result
  },
  async updateAlert (alert) {
    const result = await service.http.put(`/api/messaging/update`, alert)
    return result
  },
  async readAllAlerts (userId) {
    // leer todas las alertas
    const result = await service.http.put(`/api/messaging/readAll?userId=${userId}`, [NotificationType.REMINDER.name, NotificationType.ALERT.name])
    return result
  },
  async deleteAllAlerts (userId) {
    // eliminar todas las alertas
    const result = await service.http.put(`/api/messaging/deleteAll?userId=${userId}`, [NotificationType.REMINDER.name, NotificationType.ALERT.name])
    return result
  },
  async alertNotReadCount (userId) {
    // la cantidad de alertas no leídas (badge)
    const result = await service.http.post('/api/messaging/getBadgeForTypes',
      { userId, types: [NotificationType.REMINDER.name, NotificationType.ALERT.name] })
    return result
  },
  async alertTotalCount (userId) {
    const result = await service.http.post('/api/messaging/countForTypes',
      { userId, types: [NotificationType.REMINDER.name, NotificationType.ALERT.name] })
    return result
  }
}

export default messagingApi
