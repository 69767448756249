import {ReportConversionUtil, ConsumptionType, ValueText} from '@colven/common-domain-lib/lib'
import moment from 'moment'
import i18n from '../i18n'

const speedToStringTranslated = (speed, direction, lang) => {
  let speedResult = processSpeed(speed);

  if (speedResult || speedResult === 0) {
    let cardinalPoint = ''
    if (direction === 0) {
      cardinalPoint = i18n.t('utils.NORTH', { lang }).toString()
    } else if (direction === 90) {
      cardinalPoint = i18n.t('utils.EAST', { lang }).toString()
    } else if (direction === 180) {
      cardinalPoint = i18n.t('utils.SOUTH', { lang }).toString()
    } else if (direction === 270) {
      cardinalPoint = i18n.t('utils.WEST', { lang }).toString()
    } else if (direction > 0 && direction < 90) {
      cardinalPoint = i18n.t('utils.NORTH_EAST', { lang }).toString()
    } else if (direction > 90 && direction < 180) {
      cardinalPoint = i18n.t('utils.SOUTH_EAST', { lang }).toString()
    } else if (direction > 180 && direction < 270) {
      cardinalPoint = i18n.t('utils.SOUTH_WEST', { lang }).toString()
    } else if (direction > 270 && direction < 360) {
      cardinalPoint = i18n.t('utils.NORTH_WEST', { lang }).toString()
    }
    if (cardinalPoint.length > 0) {
      return speedResult + ' ' + i18n.t('utils.KMH_TO', { lang }) + ' ' + cardinalPoint
    } else {
      return speedResult + ' ' + i18n.t('utils.KMH', { lang })
    }
  } else {
    return ''
  }
}

const processSpeed = (speed) => {
  try {
    if (speed || speed === 0) {
      let result = parseFloat(speed).toFixed(2);
      return result;
    } else {
      return null
    }
  } catch {
    return null;
  }
}

/**
 * Convertir una velocidad y dirección a un string legible en el portal con un formato adecuado
 * @param speed
 * @param direction
 * @param stopped
 * @param stoppedTime
 * @param trackTimestamp
 * @param lang
 * @param returnDirectionStr
 * @returns
 */
const speedDetentionToStringTranslated = (
  speed, direction, stopped, stoppedTime,
  trackTimestamp, lang, returnDirectionStr = false) => {
  direction = Number(direction)
  stoppedTime = Number(stoppedTime)
  speed = Number(speed)

  let directionStr = null
  let cardinalPoint = ''
  if (direction === 0) {
    cardinalPoint = i18n.t('utils.NORTH', { lang }).toString()
    directionStr = 'north'
  } else if (direction === 90) {
    cardinalPoint = i18n.t('utils.EAST', { lang }).toString()
    directionStr = 'east'
  } else if (direction === 180) {
    cardinalPoint = i18n.t('utils.SOUTH', { lang }).toString()
    directionStr = 'south'
  } else if (direction === 270) {
    cardinalPoint = i18n.t('utils.WEST', { lang }).toString()
    directionStr = 'west'
  } else if (direction > 0 && direction < 90) {
    cardinalPoint = i18n.t('utils.NORTH_EAST', { lang }).toString()
    directionStr = 'northeast'
  } else if (direction > 90 && direction < 180) {
    cardinalPoint = i18n.t('utils.SOUTH_EAST', { lang }).toString()
    directionStr = 'southeast'
  } else if (direction > 180 && direction < 270) {
    cardinalPoint = i18n.t('utils.SOUTH_WEST', { lang }).toString()
    directionStr = 'southwest'
  } else if (direction > 270 && direction < 360) {
    cardinalPoint = i18n.t('utils.NORTH_WEST', { lang }).toString()
    directionStr = 'northwest'
  }

  let translation = ''
  if (stopped && (stoppedTime || stoppedTime === 0)) {
    const stoppedTimeConversion = ReportConversionUtil.secondsToString(stoppedTime + (moment().utc().unix() - trackTimestamp))
    translation = i18n.t('utils.STOPPED_SINCE', { lang }) + ' ' + stoppedTimeConversion
  } else if (!stopped && (speed || speed === 0)) {
    if (cardinalPoint.length > 0) {
      translation = speed.toFixed(2) + ' ' + i18n.t('utils.KMH_TO', { lang }) + ' ' + cardinalPoint
    } else {
      translation = speed.toFixed(2) + ' ' + i18n.t('utils.KMH', { lang })
    }
  } else {
    translation = ''
  }
  return returnDirectionStr
    ? { translation, directionStr }
    : translation
}

const consumptionToStringTranslated = (lang, consumption) => {
  let cons = ''
  if (!consumption) {
    return cons
  }
  switch (consumption.type) {
    case ConsumptionType.HS:
      return `${consumption.litres && consumption.litres[0] && consumption.litres[0].litres ? consumption.litres[0].litres : consumption.litres} ${i18n.t('utils.LT_PER_HOUR', { lang })}`
    case ConsumptionType.HS_SPEED:
      consumption.litres.forEach(lt => {
        if (lt.from && lt.to) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_HOUR_BETWEEN', { lang })} `
          cons += `${lt.from} ${i18n.t('utils.AND', { lang })} ${lt.to} ${i18n.t('utils.KMH', { lang })}. `
        } else if (lt.to) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_HOUR_LESS', { lang })} `
          cons += `${lt.to} ${i18n.t('utils.KMH', { lang })}. `
        } else if (lt.from) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_HOUR_MORE', { lang })} `
          cons += `${lt.from} ${i18n.t('utils.KMH', { lang })}. `
        }
      })
      return cons
    case ConsumptionType.KM:
      return `${consumption.litres && consumption.litres[0] && consumption.litres[0].litres ? consumption.litres[0].litres : consumption.litres} ${i18n.t('utils.LT_PER_KM', { lang })}`
    case ConsumptionType.KM_RPM:
      consumption.litres.forEach(lt => {
        if (lt.from && lt.to) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_KM_BETWEEN', { lang })} `
          cons += `${lt.from} ${i18n.t('utils.AND', { lang })} ${lt.to} RPM. `
        } else if (lt.to) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_KM_LESS', { lang })} `
          cons += `${lt.to} RPM. `
        } else if (lt.from) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_KM_MORE', { lang })} `
          cons += `${lt.from} RPM. `
        }
      })
      return cons
    case ConsumptionType.KM_SPEED:
      consumption.litres.forEach(lt => {
        if (lt.from && lt.to) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_KM_BETWEEN', { lang })} `
          cons += `${lt.from} ${i18n.t('utils.AND', { lang })} ${lt.to} ${i18n.t('utils.KMH', { lang })}. `
        } else if (lt.to) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_KM_LESS', { lang })} `
          cons += `${lt.to} ${i18n.t('utils.KMH', { lang })}. `
        } else if (lt.from) {
          cons += `${lt.litres} ${i18n.t('utils.LT_PER_KM_MORE', { lang })} `
          cons += `${lt.from} ${i18n.t('utils.KMH', { lang })}. `
        }
      })
      return cons
  }
}

const temperatureToStringTranslated = (lang, temperature) => {
  if (!temperature) { return undefined }
  temperature = parseFloat(temperature)
  if (Number.isNaN(temperature) || temperature === 99.9) {
    return i18n.t('utils.DISCONNECTED', { lang })
  } else {
    return temperature.toFixed(1) + 'ºC'
  }
}

const translateDecimalToValueText = (value, toFixed = 2, separatorFrom = '.', separatorTo = ',') => {
  if (value || value == 0) {
    const newValue = value.toFixed(toFixed).replace(separatorFrom, separatorTo);
    return new ValueText(Number(value.toFixed(toFixed)), newValue);
  }
}

export default {
  temperatureToStringTranslated,
  consumptionToStringTranslated,
  speedDetentionToStringTranslated,
  speedToStringTranslated,
  translateDecimalToValueText
}
