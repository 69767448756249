import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import snackbar from './modules/snackbar'
import reportDefer from './modules/report-defer'
import dialog from './modules/dialog'
import app from './modules/app'
import table from './modules/table'
import messaging from './modules/messaging'
import createLogger from 'vuex/dist/logger'
import breadcrumb from './modules/breadcrumb'
import alertNotification from './modules/alert-notification'
import reportBug from './modules/reportBug'
import changeEnterprise from './modules/changeEnterprise'
import secondFactor from '@colven/common-components'
import autoReport from './modules/autoReport'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    dialog,
    app,
    table,
    reportDefer,
    messaging,
    breadcrumb,
    alertNotification,
    reportBug,
    changeEnterprise,
    secondFactor,
    autoReport
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
