
/**
 * Store para almacenar las referencias de los reportes temporales guardados con la funcionalidad del auto-reporte
 * Se guarda la referencia a los datos en la collection de la IndexedDB y un resumen que se muestra en la lista de la barra temporal
 */
const state = {
  reports: [],
  count: 0,
  showTemporaryReportTray: false,
  currentReportId: null
}

// getters
const getters = {
  // reportes
  getReports: (state) => {
    return state.reports
  },
  // num de reportes guardados (para el badge)
  getReportsCount: (state) => {
    return state.count
  },
  // boolean para activar la bandeja temporal de reportes
  getShowTemporaryReportTray: (state) => {
    return state.showTemporaryReportTray
  },
  // id del reporte que se está mostrando en pantalla
  getCurrentReportId: (state) => {
    return state.currentReportId
  }
}

// actions
const actions = {
  // setear reportes
  setReports ({ state, commit }, reports) {
    commit('commitReports', { reports })
    if (state.count === 0) {
      commit('commitShowTemporaryReportTray', { value: false })
    }
  },
  // agregar/actualizar un reporte
  upsertReport ({ commit }, report) {
    commit('upsertReport', { report })
  },
  // setea en true el boolean que activa la bandeja temporal de reportes
  openTemporaryReportTray ({ commit }) {
    commit('commitShowTemporaryReportTray', { value: true })
  },
  // setea en false el boolean que activa la bandeja temporal de reportes
  closeTemporaryReportTray ({ commit }) {
    commit('commitShowTemporaryReportTray', { value: false })
  },
  // eliminar un reporte
  deleteReport ({ state, commit }, id) {
    commit('deleteReport', { id })
    if (state.count === 0) {
      commit('commitShowTemporaryReportTray', { value: false })
    }
  },
  // setear el id del reporte que se está generando actualmente (null si no hay ninguno generándose)
  setCurrentReportId ({ commit }, id) {
    commit('commitCurrentReportId', { id })
  }
}

// mutations
const mutations = {
  // commit reportes
  commitReports (state, { reports }) {
    state.reports = reports
    state.count = reports.length
  },
  // upsert report
  upsertReport (state, { report }) {
    const stateReport = state.reports.find(r => r.id === report.id)
    if (stateReport != null) {
      Object.assign(stateReport, report)
    } else {
      state.reports.push(report)
      state.count = state.count + 1
    }
  },
  // delete report
  deleteReport (state, { id }) {
    const index = state.reports.findIndex(r => r.id === id)
    if (index > -1) {
      state.reports.splice(index, 1)
      state.count = state.count - 1
    }
  },
  // commit showTemporaryReportTray
  commitShowTemporaryReportTray (state, { value }) {
    state.showTemporaryReportTray = value
  },
  // commit currentReportId
  commitCurrentReportId (state, { id }) {
    state.currentReportId = id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
