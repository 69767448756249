import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,{attrs:{"id":"app"}},[(_vm.displayHeader)?_c('common-navbar'):_vm._e(),_c(VMain,{staticClass:"pt-0",attrs:{"id":"content"}},[_c('router-view',{key:_vm.$route.fullPath}),_c('ReportDeferComponent'),_c('SnackbarComponent'),_c('DialogComponent'),_c('ReportBugComponent'),_c('ChangeEnterpriseComponent'),_c('SecondFactorComponent'),_c('TemporaryReportTrayComponent'),_c(VSnackbar,{attrs:{"timeout":-1,"value":_vm.showUpdateUI}},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-update")]),_vm._v(" "+_vm._s(_vm.$t('updateMessage'))+" "),_c(VBtn,{staticClass:"ml-2",attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.updateUI()}}},[_vm._v(_vm._s(_vm.$t('yes')))]),_c(VBtn,{attrs:{"text":"","dark":""},on:{"click":() => { _vm.showUpdateUI = false }}},[_vm._v(_vm._s(_vm.$t('no')))])],1)],1),_c('common-footer',{staticClass:"z-index-1"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }