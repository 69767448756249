import { DialogType } from '@/constants/constants'

export const state = () => ({
  // Para hacerlo visible u ocultarlo
  visible: false,
  // El texto que muestra el diálogo
  text: '',
  // El título del diálogo
  title: '',
  // El tipo del diálogo
  type: DialogType.INFO,
  // El color del ícono, de acuerdo al tipo
  color: 'info',
  // Si se muestran, o no, los botones de acción
  actionButtons: false,
  // Opacidad del overlay
  overlayOpacity: 0.5,
  yesAction: () => { console.log('Not implemented') },
  noAction: () => { console.log('Not implemented') }
})

const getters = {
  getText: (state) => {
    return state.text
  },
  getTitle: (state) => {
    return state.title
  },
  getType: (state) => {
    return state.type
  },
  getColor: (state) => {
    return state.color
  },
  getActionButtons: (state) => {
    return state.actionButtons
  },
  getVisible: (state) => {
    return state.visible
  },
  getOverlayOpacity: (state) => {
    return state.overlayOpacity
  }
}

const actions = {
  setText ({ commit }, text) {
    commit('commitText', { text })
  },
  setTitle ({ commit }, title) {
    commit('commitTitle', { title })
  },
  setType ({ commit }, type) {
    commit('commitType', { type })
  },
  setColor ({ commit }, color) {
    commit('commitColor', { color })
  },
  setActionButtons ({ commit }, actionButtons) {
    commit('commitActionButtons', { actionButtons })
  },
  setVisible ({ commit }, visible) {
    commit('commitVisible', { visible })
  },
  setOverlayOpacity ({ commit }, overlayOpacity) {
    commit('commitOverlayOpacity', { overlayOpacity })
  },
  openDialog ({ commit }, dialogSettings) {
    commit('openDialog', dialogSettings)
  }
}

const mutations = {
  commitTitle (state, { title }) {
    state.title = title
  },
  commitText (state, { text }) {
    state.text = text
  },
  commitType (state, { type }) {
    state.type = type
  },
  commitColor (state, { color }) {
    state.color = color
  },
  commitActionButtons (state, { actionButtons }) {
    state.actionButtons = actionButtons
  },
  commitVisible (state, { visible }) {
    state.visible = visible
  },
  commitOverlayOpacity (state, { overlayOpacity }) {
    state.overlayOpacity = overlayOpacity
  },
  // Abre el diálogo con los datos enviados en el payload
  openDialog (state, payload) {
    if (payload.yesAction) {
      state.yesAction = payload.yesAction
    } else {
      state.yesAction = null
    }
    if (payload.noAction) {
      state.noAction = payload.noAction
    } else {
      state.noAction = null
    }
    state.title = payload.title
    state.text = payload.text
    state.type = payload.type ? payload.type : DialogType.INFO
    state.overlayOpacity = payload.overlayOpacity ? payload.overlayOpacity : 0.5
    // Setear el color del ícono dependiendo el tipo de diálogo
    switch (state.type) {
      case DialogType.ERROR:
        state.color = 'error'
        break
      case DialogType.WARNING:
        state.color = 'warning'
        break
      case DialogType.SUCCESS:
        state.color = 'success'
        break
      case DialogType.INFO:
        state.color = 'info'
        break
      case DialogType.QUESTION:
        state.color = 'info'
        break
      default:
        state.color = 'info'
        break
    }
    state.actionButtons = payload.actionButtons
    state.visible = true
  },
  // Cierra el diálogo
  closeDialog (state) {
    state.visible = false
    state.title = ''
    state.text = ''
    state.type = DialogType.INFO
    state.color = 'info'
    state.actionButtons = false
    state.overlayOpacity = 0.5
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
