import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nOriginal from './i18nOriginal'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages: JSON.parse(i18nOriginal.original),
  silentFallbackWarn: false
})
