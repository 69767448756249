/**
 * Constantes que definen el tipo de diálogo
 */
export const DialogType = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'thumb_up',
  INFO: 'info',
  QUESTION: 'contact_support'
}

/**
 * Constantes que definen el tipo de snackbar
 */
export const SnackbarStyle = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'done',
  INFO: 'info'
}

/**
 * Tema por defecto
 */
export const ColvenTheme = {
  light: {
    primary: '#0694de',
    secondary: '#1976d2',
    accent: '#69CAFF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    'icon-primary': '#0694de',
    'icon-secondary': '#1976d2'
  },
  dark: {
    primary: '#616161',
    secondary: '#455a64',
    accent: '#768C97',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    'icon-primary': '#616161',
    'icon-secondary': '#455a64'
  }
}

/**
 * Estas constantes definen el tipo de restablecimiento de la contraseña:
 * 1- Restablecimiento por parte del usuario (RESET);
 * 2- Restablecimiento por fecha de expiración de la contraseña (EXPIRATION);
 * 3- Restablecimiento en el siguiente inicio de sesión (NEXT_LOGIN).
 */
export const ResetPasswordType = {
  USER_RESET: 'RESET',
  EXPIRATION: 'EXPIRATION_RESET',
  NEXT_LOGIN: 'NEXT_LOGIN_RESET'
}

/**
 * Constantes para la configuración del componente mapa
 */
export const MapConfig = {
  height: 600,
  mapTiles: [
    { url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga', name: 'Google Road', icon: 'map' },
    { url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga', name: 'Google Satellite', icon: 'satellite', base: true },
    { url: 'https://mt0.google.com/vt/lyrs=s,m&hl=en&x={x}&y={y}&z={z}&s=Ga', name: 'Google Hybrid', icon: 'satellite' },
    { url: 'https://mt0.google.com/vt/lyrs=t,m&hl=en&x={x}&y={y}&z={z}&s=Ga', name: 'Google Terrain', icon: 'terrain' },
    { url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', name: 'Wikimedia', icon: 'map' },
    { url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.jpg', name: 'ArcGIS Satellite', icon: 'satellite' },
    { url: 'https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=CIekAclgCLfQy03ebnmC', name: 'Topographic', icon: 'terrain' }
  ]
}

/**
 * Se definen los tipos de filtros que son usados en el componente tabla
 */
export const FilterType = {
  textField: 'TEXT_FIELD',
  select: 'SELECT',
  multiSelect: 'MULTISELECT'
}

/**
 * Tipos de datos para cada columna
 */
export const DataType = {
  string: 'STRING',
  number: 'NUMBER',
  boolean: 'BOOLEAN',
  options: 'OPTIONS'
}
/**
 * Tipos de gráficos (usados en el componente genérico)
 */
export const ChartType = {
  BAR: 'bar',
  HORIZONTAL_BAR: 'horizontalBar',
  LINE: 'line',
  PIE: 'pie',
  DOUGHNUT: 'doughnut',
  COMPOSITE: 'composite'
}

/**
 * Tipo de Dialog para las acciones en el componente tabla genérico
 */
export const TableDialogType = {
  MODIFY_COLUMNS: 'MODIFY_COLUMNS',
  CHARTS: 'CHARTS',
  MAPS: 'MAPS'
}

/**
 * Tipo de Serie a insertar en un mapa
 */
export const SerieType = {
  LINE: 'LINE',
  POLYGON: 'POLYGON',
  MARKERS: 'MARKERS',
  LINE_MARKER: 'LINE_MARKER',
  POLYGON_MARKER: 'POLYGON_MARKER'
}

/**
 * Config de Firebase
 */
export const FirebaseConfig = {
  apiKey: 'AIzaSyCxo22JHAeXAMQg7xRWzVWSrgDVXp4rjIw',
  authDomain: 'portal-agro-b6293.firebaseapp.com',
  databaseURL: 'https://portal-agro-b6293.firebaseio.com',
  projectId: 'portal-agro-b6293',
  storageBucket: 'portal-agro-b6293.appspot.com',
  messagingSenderId: '937829640157',
  appId: '1:937829640157:web:caccad46906b66c5a23834',
  measurementId: 'G-ZMRY5FD35E'
}

/**
 * Íconos y colores para cada tipo de mensaje de Firebase (notificaciones y alertas)
 */
export const FirebaseMessageConfig = {
  ALERT_DRIVE_UNIT_PENDING_HECTARES: {
    icon: 'fa-exclamation-circle',
    color: '#2196F3'
  },
  ALERT_DRIVE_UNIT_TO_FISNISH: {
    icon: 'fa-trophy',
    color: '#4CAF50'
  },
  ALERT_SPEEDING: {
    icon: 'mdi-speedometer',
    color: '#f44336'
  },
  ALERT_OUT_OF_SPEED_RANGE: {
    icon: 'fa-tachometer-alt',
    color: '#f44336'
  },
  REPORT: {
    icon: 'fa-list-alt',
    color: '#42A5F5'
  },
  SCORING_REPORT: {
    icon: 'fa-list-alt',
    color: '#42A5F5'
  },
  ERROR: {
    icon: 'fa-exclamation-circle',
    color: '#F44336'
  },
  // defecto (si no hay un tipo definido)
  DEFAULT: {
    icon: 'mdi-message-text',
    color: '#607D8B'
  },
  REMINDER: {
    name: 'REMINDER',
    icon: 'fa-sticky-note',
    color: '#47A34B'
  },
  ALERT: {
    name: 'ALERT',
    icon: 'fa-exclamation-triangle',
    color: '#FB8C00'
  }
}

export const stateConstants = {

  SEND: 'send',
  NOT_SEND: 'not_send',
  DELIVERED: 'delivered',
  READY: 'ready',
  COMMAND_RESPONSE: 'commandResponse',
  INIT: 'init',
  END: 'end'

}
/**
 * Tamaños de página para las notificaciones y las alertas
 */
export const NOTIFICATIONS_PAGE_SIZE = 10
export const ALERTS_PAGE_SIZE = 10

/**
 * Configuración por default referencias de mapa de área trabajada
 */
export const areaPercentageMap = {
  references: [
    {
      name: '10-percent',
      color: '#F4F2D9',
      isInterval: true,
      intervalStart: 0,
      intervalEnd: 10
    },
    {
      name: '20-percent',
      color: '#ECE6C5',
      isInterval: true,
      intervalStart: 10,
      intervalEnd: 20
    },
    {
      name: '30-percent',
      color: '#E5D7B2',
      isInterval: true,
      intervalStart: 20,
      intervalEnd: 30
    },
    {
      name: '40-percent',
      color: '#DCC69F',
      isInterval: true,
      intervalStart: 30,
      intervalEnd: 40
    },
    {
      name: '50-percent',
      color: '#D4B28D',
      isInterval: true,
      intervalStart: 40,
      intervalEnd: 50
    },
    {
      name: '60-percent',
      color: '#CA9D7C',
      isInterval: true,
      intervalStart: 50,
      intervalEnd: 60
    },
    {
      name: '70-percent',
      color: '#C0876B',
      isInterval: true,
      intervalStart: 60,
      intervalEnd: 70
    },
    {
      name: '80-percent',
      color: '#B56F5B',
      isInterval: true,
      intervalStart: 70,
      intervalEnd: 80
    },
    {
      name: '90-percent',
      color: '#AA574B',
      isInterval: true,
      intervalStart: 80,
      intervalEnd: 90
    },
    {
      name: '100-percent',
      color: '#9E3E3C ',
      isInterval: true,
      intervalStart: 90
    }
  ]
}

/**
 * Configuración por default referencias de mapa de velocidad del detalle de área trabajada
 */
export const detailsSpeedMap = {
  references: [
    {
      name: 'lento',
      color: '#FFF933',
      isInterval: true,
      intervalStart: 0,
      intervalEnd: 4
    },
    {
      name: 'normal',
      color: '#80FF33',
      isInterval: true,
      intervalStart: 4,
      intervalEnd: 8
    },
    {
      name: 'rapido',
      color: '#F90611',
      isInterval: true,
      intervalStart: 8,
      intervalEnd: 100
    }
  ]
}

export const traceColors = [
  "#b912b6",
  "#eec69a",
  "#bd0e76",
  "#d78d1e",
  "#572e8c",
  "#e21b7f",
  "#468fc8",
  "#553147",
  "#554d8e",
  "#fbde8d",
  "#b70552",
  "#767bd6",
  "#253721",
  "#c5eacb",
  "#ca3c73",
  "#0db1fd",
  "#eedb59",
  "#961648",
  "#461090",
  "#da95aa",
  "#67899d",
  "#ae1e36",
  "#8974cf",
  "#a97115",
  "#dedfe4",
  "#1915a1",
  "#7f07f3",
  "#fe2b91",
  "#fa98ed",
  "#9375ba",
  "#391f72",
  "#c70d49",
  "#a28b0d",
  "#e162e6",
  "#0b1303",
  "#193f8b",
  "#9d7e53",
  "#fca245",
  "#498dac",
  "#0152ce",
  "#a53413",
  "#31ddef",
  "#e994f0",
  "#780850",
  "#644c2d",
  "#2107a1",
  "#afc4fd",
  "#facd40",
  "#c701e0",
  "#050e2b",
  "#baa783"
];
export const objectiveTendencyStatesKeys = ["IDLE", "WORKING", "MANEUVERING", "PARADA", "FALTA_VACIO"]
