import messagingApi from '@/api/messaging.api'
import authApi from '@/api/auth.api'
import { messagingService } from '@/business/messagingService'
import { FirebaseMessageConfig, NOTIFICATIONS_PAGE_SIZE } from '@/constants/constants'
import { MESSAGES_TYPES } from '@colven/common-domain-lib/lib'
import deepcopy from 'deepcopy'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { navBarService } from '@/business/navBarService'
import autoReportService from '@/business/autoReportService'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'

export default {
  name: 'NavbarComponent',
  components: {
    AutocompleteComponent
  },
  props: {
  },
  data () {
    return {
      logo: undefined,
      selectedEntity: undefined,
      notificationMenu: false,
      pageNumber: 1,
      showSnackbarNotification: false,
      username: undefined,
      // para activar el badge con el num de auto-reportes guardados
      hoverAutoReportBtn: false
    }
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.logo = localStorage.getItem('logo')
    this.userId = localStorage.getItem('userId')
    this.username = localStorage.getItem('username')
    this.initializeNotificationMenu()
    this.setNavBarButtons(navBarService.getMenuByUser())
    this.mergeLocales()
  },
  updated () {
  },
  computed: {
    ...mapState('app', {
      // navBarButtons: state => state.navBarButtons,
      navBarSettings: state => state.navBarSettings,
      breadcrumbRoute: state => state.breadcrumbRoute,
      displayBreadcrumb: state => state.displayBreadcrumb,
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration,
      notifications: state => state.notifications,
      badgeCount: state => state.badgeCount,
      notificationSound: state => state.notificationSound,
      newNotification: state => state.newNotification,
      navBarButtons: state => state.navBarButtons
    }),
    ...mapGetters({
      showGetMoreNotifications: 'app/isDisplayShowMoreNotifications',
      getUsername: 'auth/getUsername',
      getLocales: 'auth/getLocales',
      reportsCount: 'autoReport/getReportsCount'
    }),
    filteredNotifications () {
      return this.notifications.filter(notification => !notification.delete)
    },
    badgeCountComputed () {
      if (this.badgeCount >= 100) {
        return '99+'
      } else {
        return this.badgeCount
      }
    },
    entitySelectorItemsSorted () {
      const selectorItemsCopy = deepcopy(this.entitySelectorItems)
      const that = this
      return selectorItemsCopy.sort(function sortFunction (a, b) {
        const nameA = a[that.entitySelectorConfiguration.text] ? a[that.entitySelectorConfiguration.text].toLowerCase() : null
        const nameB = b[that.entitySelectorConfiguration.text] ? b[that.entitySelectorConfiguration.text].toLowerCase() : null
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      })
    },
    /**
     * Para mostrar solamente los headers que tengan opciones
     */
    navBarButtonsFiltered () {
      return this.navBarButtons.filter(menu => menu.subMenu.length > 0)
    }
  },
  created () {
    this.$eventBus.$on('updateLogo', this.updateLogo)
    this.$store.watch(state => state.app.selectedItem, () => {
      if (this.$store.state.app.selectedItem) {
        this.selectedEntity = this.$store.state.app.selectedItem
      } else if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
        this.selectedEntity = this.$store.state.app.entitySelectorItems[0]
      }
    })
    this.$_debounceTimeoutId = null
    this.$store.watch(state => state.app.newNotification, () => {
      if (this.$store.state.app.newNotification) {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
        this.showSnackbarNotification = true
        this.$_debounceTimeoutId = setTimeout(() => {
          this.showSnackbarNotification = false
          this.resetNewNotification()
        }, 3000)
      }
    })
    // console.log('init notifications')
    // this.getMoreNotifications()
  },
  beforeDestroy () {
    this.$eventBus.$off('updateLogo')
    clearTimeout(this.$_debounceTimeoutId)
    this.$_debounceTimeoutId = null
  },
  methods: {
    ...mapMutations('app', [
      'setSelectedItem',
      'setNotifications',
      'addNotifications',
      'readNotification',
      'readAllNotifications',
      'deleteNotification',
      'deleteAllNotifications',
      'setBadgeCount',
      'incrementBadgeCount',
      'reduceBadgeCount',
      'switchNotificationSound',
      'resetNewNotification',
      'setNotificationsTotalCount',
      'setBadgeCount',
      'resetAppData',
      'setNavBarButtons'
    ]),
    ...mapActions({
      'setUserName': 'auth/setUsername',
      'setPassword': 'auth/setPassword',
      'setJwt': 'auth/setJwt',
      'clearSessionData': 'auth/clearSessionData',
      'resetAlerts': 'alertNotification/resetAlerts',
      'openReportBugDialog': 'reportBug/openReportBugDialog',
      'setFieldsRequired': 'reportBug/setFieldsRequired',
      'openChangeEnterpriseDialog': 'changeEnterprise/openChangeEnterpriseDialog',
      'openSecondFactorDialog': 'secondFactor/openSecondFactorDialog',
      'setAutoReports': 'autoReport/setReports',
      'openTemporaryReportTray': 'autoReport/openTemporaryReportTray'
    }),
    resetNavbarData () {
      this.logo = undefined
      this.selectedEntity = undefined
      this.notificationMenu = false
      this.pageNumber = 1
      this.showSnackbarNotification = false
    },
    userProfiles () {
      this.$router.push('/user-profiles').catch(err => {
        console.log(err)
      })
    },
    mergeLocales () {
      const locales = this.getLocales
      if (locales != null) {
        if (locales.es != null) {
          this.$i18n.mergeLocaleMessage('es', locales.es)
        }
        if (locales.pt != null) {
          this.$i18n.mergeLocaleMessage('pt', locales.pt)
        }
        if (locales.en != null) {
          this.$i18n.mergeLocaleMessage('en', locales.en)
        }
      }
    },
    updateLogo (logo) {
      this.logo = logo
    },
    async getMoreNotifications () {
      if (this.userId) {
        await this.getAndSetBadgeCount()
        this.pageNumber++
        messagingApi.findAllForType(NOTIFICATIONS_PAGE_SIZE, this.pageNumber, this.userId).then(response => {
          this.addNotifications(response.data)
        })
      }
    },
    readNotificationAction (notification) {
      const notificationCopy = deepcopy(notification)
      notificationCopy.show = !notification.show
      messagingApi.update(notificationCopy)
      this.readNotification({ _id: notificationCopy._id, value: notificationCopy.show })
      if (notificationCopy.show) {
        this.reduceBadgeCount()
      } else {
        this.incrementBadgeCount()
      }
    },
    async logout () {
      await authApi.logout()
      this.clearSessionData()

      // eliminar reportes de la base de datos indexada
      autoReportService.deleteReports()

      this.resetAppData()
      this.resetAlerts()
      this.resetNavbarData()
      this.$router.push('/login')
    },
    deleteNotificationAction (notification) {
      const notificationCopy = deepcopy(notification)
      notificationCopy.delete = true
      messagingApi.update(notificationCopy)
      this.deleteNotification({ _id: notificationCopy._id })
      this.reduceBadgeCount()
    },
    notificationClicked (notification) {
      if (!notification.show) {
        this.readNotificationAction(notification)
      }
      switch (notification.type) {
        case MESSAGES_TYPES.REPORT:
          this.$router.push({ path: notification.url, query: { key: notification.key } }).catch(() => {})
          break
      }
    },
    async markAllAsRead () {
      await messagingApi.readAll(this.userId)
      this.readAllNotifications({ value: true })
      await this.getAndSetBadgeCount()
    },
    async deleteAll () {
      await messagingApi.deleteAll(this.userId)
      this.deleteAllNotifications({ value: true })
      await this.getAndSetBadgeCount()
    },
    async initializeNotificationMenu () {
      if (this.userId) {
        this.getAndSetBadgeCount()
        const currentNotificationsCount = await messagingService.getNotificationsTotalCount(this.userId)
        this.setNotificationsTotalCount(currentNotificationsCount)
        messagingService.findNotifications(NOTIFICATIONS_PAGE_SIZE, this.pageNumber, this.userId).then(response => {
          this.setNotifications(response)
        })
      }
    },
    getType (notification) {
      return FirebaseMessageConfig[notification.type]
    },
    getIcon (notification) {
      const menu = JSON.parse(localStorage.getItem('menu'))
      let subMenu = null
      let icon = null
      if (menu) {
        menu.forEach(m => {
          subMenu = m.subMenu.find(sm => sm.route === notification.url) ? m.subMenu.find(sm => sm.route === notification.url) : subMenu
        })
        icon = subMenu ? subMenu.icon : null
      }
      if (!icon) {
        switch (notification.url) {
          case '/dashboard/home':
            icon = 'dashboard'
            break
          case '/dashboard/alert-dashboard':
            icon = 'mdi-alert'
            break
          case '/dashboard/engine-state-dashboard':
            icon = 'mdi-engine'
            break
          case '/dashboard/harvester':
            icon = 'spa'
            break
          case '/dashboard/truck':
            icon = 'commute'
            break
          case '/dashboard/map':
            icon = 'map'
            break
          case '/dashboard/position':
            icon = 'location_on'
            break
          case '/dashboard/running-hours':
            icon = 'timer'
            break
          case '/dashboard/real-speed':
            icon = 'speed'
            break
          case '/dashboard/current-status':
            icon = 'departure_board'
            break
          case '/dashboard/machine-summary':
            icon = 'mdi-tractor'
            break
          case '/dashboard/on-board-monitor':
            icon = 'tablet_mac'
            break
          case '/dashboard/objective-tendency':
            icon = 'tablet_mac'
            break
          default:
            icon = FirebaseMessageConfig[notification.type].icon
        }
        return icon
      } else {
        return icon
      }
    },
    async getAndSetBadgeCount () {
      if (this.userId) {
        const badgeConstResponse = await messagingApi.notReadCountForType(this.userId)
        this.setBadgeCount({ badgeCount: badgeConstResponse.data.count })
      }
    },
    parseDateTime (dateTime) {
      const momentDate = moment(dateTime)
      return momentDate.format('DD/MM/YY-HH:mm:ss')
    },
    closeSnackbarNotification () {
      clearTimeout(this.$_debounceTimeoutId)
      this.$_debounceTimeoutId = null
      this.showSnackbarNotification = false
      this.resetNewNotification()
    },
    /**
     * Dialog para reportar errores
     */
    reportBug () {
      this.setFieldsRequired(true)
      this.openReportBugDialog()
    },
    /**
     * Dialog para cambiar la empresa seleccionada para el login
     */
    changeEnterprise () {
      this.openChangeEnterpriseDialog()
    },
     /**
     * Dialog para modificar la configuracion de 2do factor de autentificacion
     */
     secondFactor () {      
      this.openSecondFactorDialog()
    },
    /**
     * Si hay reportes guardados, se abre la bandeja
     */
    openTray () {
      if (this.reportsCount > 0) {
        this.openTemporaryReportTray()
      }
    },
    selectedFunction (newValue) {
      this.selectedEntity = newValue
    }
  },
  watch: {
    selectedEntity () {
      this.setSelectedItem(this.selectedEntity)
    },
    $route (to, from) {
      if (from && from.name === 'login') {
        this.userId = localStorage.getItem('userId')
        this.username = localStorage.getItem('username')
      }
      this.notificationMenu = false
    }
  }
}
