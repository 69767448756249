import Vue from 'vue'
import { privilegesService } from '../business/privilegesService'

function initMixis () {
  Vue.mixin({
    methods: {
      validatePrivilegesOr: privilegesService.validatePrivilegesOr,
      validatePrivilegesAnd: privilegesService.validatePrivilegesAnd
    }
  })
}

export default initMixis
