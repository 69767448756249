import authApi from '@/api/auth.api'
import store from '../store/store'
import Vue from 'vue'
import { navBarService } from '@/business/navBarService'
import { mapCurrentStateService } from '@/business/mapCurrentStateService'
import autoReportService from '@/business/autoReportService'

export const loginService = (function () {
  'use strict'

  async function login (userName, password) {
    const authDto = {
      username: userName,
      password: password
    }
    const result = await authApi.validateUser(authDto)
    return result.data
  }

  async function refreshToken (enterprise) {
    const result = await authApi.refreshToken({ enterprise })
    return result.data
  }
  async function refreshTokenSecondFactor (enterprise, secondFactorToken) {
    const result = await authApi.refreshTokenSecondFactor({ enterprise, secondFactorToken })
    return result.data
  }
  async function refreshAndUpdateUserData (enterprise, i18nInstance) {
    const userSession = await this.refreshToken(enterprise)
    this.updateUserData(userSession, i18nInstance)
  }

  async function updateUserData (userSession, i18nInstance, loginOrChangeEnterprise = false) {
    store.dispatch('auth/setJwt', userSession.sessionToken)
    store.dispatch('auth/setToken', userSession.sessionToken)

    if (userSession.enterprise && userSession.enterprise._id) {
      localStorage.setItem('enterpriseId', JSON.stringify(userSession.enterprise._id))
      localStorage.setItem('timezone', JSON.stringify(userSession.enterprise.timezone))
    } else {
      localStorage.removeItem('enterpriseId')
    }

    if (userSession.enterprise && userSession.enterprise.themes) {
      store.dispatch('auth/setThemes', userSession.enterprise.themes)
    } else {
      localStorage.removeItem('themes')
      store.dispatch('auth/setThemes', undefined)
    }

    if (userSession.enterprise && userSession.enterprise.logo) {
      store.dispatch('auth/setLogo', userSession.enterprise.logo)
      Vue.prototype.$eventBus.$emit('updateLogo', userSession.enterprise.logo)
    } else {
      localStorage.removeItem('logo')
      store.dispatch('auth/setLogo', undefined)
      Vue.prototype.$eventBus.$emit('updateLogo', undefined)
    }

    if (userSession.enterprise && userSession.enterprise.loginLogo) {
      store.dispatch('auth/setLoginLogo', userSession.enterprise.loginLogo)
    } else {
      localStorage.removeItem('loginLogo')
      store.dispatch('auth/setLoginLogo', undefined)
    }

    if (userSession.enterprise && userSession.enterprise.loginBackgroundImage) {
      store.dispatch('auth/setLoginBackgroundImage', userSession.enterprise.loginBackgroundImage)
    } else {
      localStorage.removeItem('loginBackgroundImage')
      store.dispatch('auth/setLoginBackgroundImage', undefined)
    }

    if (userSession.enterprise && userSession.enterprise.customTimeSpan) {
      localStorage.setItem('enterpriseTimeSpan', JSON.stringify(userSession.enterprise.customTimeSpan))
    } else {
      localStorage.removeItem('enterpriseTimeSpan')
    }

    if (userSession.enterprise && userSession.enterprise.harvestStartTime) {
      localStorage.setItem('harvestStartTime', JSON.stringify(userSession.enterprise.harvestStartTime))
    } else {
      localStorage.removeItem('harvestStartTime')
    }

    store.dispatch('auth/setMenu', userSession.enterprise && userSession.enterprise.menu ? userSession.enterprise.menu : null)
    store.dispatch('app/setNavBarButtons', navBarService.getMenuByUser())

    if (userSession.enterprise && userSession.enterprise.locales) {
      store.dispatch('auth/setLocales', userSession.enterprise.locales)
      if (userSession.enterprise.locales.es) {
        i18nInstance.mergeLocaleMessage('es', userSession.enterprise.locales.es)
      }
      if (userSession.enterprise.locales.pt) {
        i18nInstance.mergeLocaleMessage('pt', userSession.enterprise.locales.pt)
      }
      if (userSession.enterprise.locales.en) {
        i18nInstance.mergeLocaleMessage('en', userSession.enterprise.locales.en)
      }
    }

    localStorage.setItem('userConfiguration', JSON.stringify(userSession.configurations))
    store.dispatch('auth/setPrivileges', userSession.user.privileges)
    store.dispatch('auth/setAdmin', userSession.user.superAdmin || false)

    // map line patterns
    if (userSession.enterprise && userSession.enterprise.mapLinePatterns) {
      store.dispatch('app/setMapLinePatternsEnabled', userSession.enterprise.mapLinePatternsEnabled)
      store.dispatch('app/setMapLinePatterns', userSession.enterprise.mapLinePatterns)
    } else {
      store.dispatch('app/setMapLinePatternsEnabled', userSession.enterprise.mapLinePatternsEnabled)
      store.dispatch('app/setMapLinePatterns', null)
    }

    // si es el login o un cambio de empresa, se eliminan los reportes guardados y se obtienen los íconos para el mapa
    if (loginOrChangeEnterprise) {
      autoReportService.deleteReports()
      mapCurrentStateService.getIcons()
      localStorage.removeItem('mapPositionZoom')
    }
  }

  return {
    login,
    refreshToken,
    refreshAndUpdateUserData,
    updateUserData,
    refreshTokenSecondFactor
  }
})()
