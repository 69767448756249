import DialogComponent from '@/components/commons/dialog/DialogComponent.vue'
import FooterComponent from '@/components/commons/footer/FooterComponent.vue'
import NavbarComponent from '@/components/commons/navbar/NavbarComponent.vue'
import SidebarComponent from '@/components/commons/sidebar/SidebarComponent.vue'
import SnackbarComponent from '@/components/commons/snackbar/SnackbarComponent.vue'
import ReportDeferComponent from '@/components/commons/report-defer/ReportDeferComponent.vue'
import ToolSearchComponent from '@/components/search/ToolSearch.vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import * as firebase from 'firebase/app'
import '@firebase/messaging'
// Add the Performance Monitoring library
import 'firebase/performance'
import 'firebase/analytics'
import { messagingService } from '@/business/messagingService'
import i18n from './i18n'
import { SnackbarStyle, FirebaseConfig, NOTIFICATIONS_PAGE_SIZE, ALERTS_PAGE_SIZE, ColvenTheme } from '@/constants/constants'
import { MESSAGES_TYPES } from '@colven/common-domain-lib/lib'
import userApi from '@/api/user.api'
import ReportBugComponent from '@/components/report-bug/ReportBugComponent.vue'
import ChangeEnterpriseComponent from '@/components/change-enterprise/ChangeEnterpriseComponent.vue'
import indexedDBService from '@/indexedDB/indexedDB.service'
import { loginService } from '@/business/loginService'
import TemporaryReportTrayComponent from '@/components/temporary-report-tray/TemporaryReportTrayComponent.vue'

export default {
  components: {
    'common-navbar': NavbarComponent,
    'common-footer': FooterComponent,
    'common-sidebar': SidebarComponent,
    SnackbarComponent,
    DialogComponent,
    ReportDeferComponent,
    ToolSearchComponent,
    ReportBugComponent,
    ChangeEnterpriseComponent,
    TemporaryReportTrayComponent
  },
  data: () => ({
    lastBadgeCount: undefined,
    // para activar el prompt de actualización de versión
    showUpdateUI: false
  }),
  computed: {
    ...mapState('app', [
      'displayHeader',
      'displayFooter',
      'notificationSound',
      'badgeCount'
    ]),
    ...mapGetters({
      'getNotificationsCount': 'app/getNotificationsCount',
      'getNotificationsStore': 'app/getNotifications',
      'getAlertSound': 'alertNotification/getAlertSound',
      'getThemes': 'auth/getThemes',
      'getDark': 'app/getDark'
    })
  },
  async created() {
    // indexed DB
    await indexedDBService.openDB()

    // cargar los auto-reportes de la base de datos indexada al store
    indexedDBService.getAll('autoReports').then(reports => {
      this.setAutoReports(reports.map(report => ({
        id: report.id,
        route: report.route,
        title: report.title,
        name: report.name,
        icon: report.icon,
        color: report.color,
        lastGeneratedDate: report.lastGeneratedDate
      })))
    })

    await this.refreshToken()

    const userId = localStorage.getItem('userId')
    // notificaciones y alertas
    this.getNotifications(userId)
    this.getAlerts(userId)

    // Initialize Firebasepdated upstream
    firebase.initializeApp(FirebaseConfig)
    // Initialize Analytics
    firebase.analytics()
    // Initialize Performance Monitoring and get a reference to the service
    firebase.performance()

    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.')
        // TODO(developer): Retrieve an Instance ID token for pdated upstreamuse with FCM.
        // [START_EXCLUDE]
        // In many cases once an app has been granted notification permission,
        // it should update its UI reflecting this.
        // Retrieve Firebase Messaging object.
        // const messaging = firebase.messaging()
        // Add the public key generated from the console here.
        // messaging.usePublicVapidKey('BPYfsvqxOS3s1KJbQ03aC1bxv-mQUtheMx4KIftsrKPI2viZn-RSnrIjRx9_R6hOUiyMUA1RiNGq1PCPoP1uQ-4')
        const messaging = firebase.messaging()
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging.getToken().then((currentToken) => {
          if (currentToken) {
            this.$store.commit('messaging/setFirebaseToken', { firebaseToken: currentToken })
            const userId = localStorage.getItem('userId')
            if (userId) {
              userApi.updateAlertConfiguration({ userId, firebaseToken: currentToken })
            }
          } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.')
            // Show permission UI.
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })

        // Actualización de token
        messaging.onTokenRefresh((token) => {
          if (token) {
            this.$store.commit('messaging/setFirebaseToken', { firebaseToken: token })
            const userId = localStorage.getItem('userId')
            if (userId) {
              userApi.updateAlertConfiguration({ userId, firebaseToken: token })
            }
          }
        })

        /*
          Manejo de mensajes de Firebase
        */
        messaging.onMessage((payload) => {
          // console.log('Message received. ', payload)
          this.firebaseMessageHandler(payload)
        })
      } else {
        console.log('Unable to get permission to notify.')
      }
      this.alertNotificationsNeeded(permission)
    })

    /*
      Cuando la página está en segundo plano, las notificaciones de Firebase que llegan, no siguen el flujo normal
      Por ello, se activó esta verificación cada vez que el portal pasa a primer plano
    */
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        // Tab visible
        const routeName = this.$router.currentRoute.name
        if (routeName !== 'login' && routeName !== 'resetPasswordUser' && routeName !== 'resetPasswordExpiration' &&
          routeName !== 'resetPasswordNextLogin' && routeName !== 'emailResetPassword') {
          await this.getNotifications()
          await this.getAlerts()
          if (this.lastBadgeCount != null && this.badgeCount !== this.lastBadgeCount) {
            this.setNewNotification({ title: i18n.t('navbar.notifications.newNotifications') })
          }
          this.lastBadgeCount = null
        }
      } else {
        // Tab not visible
        this.lastBadgeCount = this.badgeCount
      }
    })

    /**
     * Si se detectó un cambio de versión, se muestra el snackbar
     */
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpdateUI = true
      })
    }
  },
  beforeMount() {
    // Carga los temas
    if (this.getThemes) {
      this.$vuetify.theme.themes.light = this.getThemes.light
      this.$vuetify.theme.themes.dark = this.getThemes.dark
    } else {
      this.$vuetify.theme.themes.light = ColvenTheme.light
      this.$vuetify.theme.themes.dark = ColvenTheme.dark
    }
    this.$vuetify.theme.dark = this.getDark
  },
  methods: {
    ...mapMutations('app', [
      'setNotifications',
      'setBadgeCount',
      'addNewNotification',
      'setNewNotification',
      'setNotificationsTotalCount',
      'setNavBarButtons'
    ]),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar',
      'setAlerts': 'alertNotification/setAlerts',
      'setAlertsTotal': 'alertNotification/setAlertsTotal',
      'setAlertsBadge': 'alertNotification/setBadge',
      'addNewAlert': 'alertNotification/addNewAlert',
      'setAutoReports': 'autoReport/setReports'
    }),
    ...mapActions('auth', {
      setJwt: 'setJwt',
      setLocale: 'setLocale',
      setToken: 'setToken',
      setPrivileges: 'setPrivileges',
      setAdmin: 'setAdmin',
      setLocales: 'setLocales',
      setMenu: 'setMenu'
    }),
    playSound() {
      var audio = new Audio('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3')
      audio.play()
    },
    /**
     * Busca las últimas 10 notificaciones del usuario
     */
    async getNotifications() {
      const userId = localStorage.getItem('userId')
      if (userId) {
        // badge
        messagingService.getNotReadCount(userId).then((badgeCount) => {
          this.setBadgeCount({ badgeCount })
        })
        // total
        messagingService.getNotificationsTotalCount(userId).then((totalCount) => {
          this.setNotificationsTotalCount(totalCount)
        })
        // notificaciones
        messagingService.findNotifications(NOTIFICATIONS_PAGE_SIZE, 1, userId).then(response => {
          this.setNotifications(response)
        })
      }
    },
    /**
     * Busca las últimas 10 alertas del usuario
     */
    async getAlerts() {
      const userId = localStorage.getItem('userId')
      if (userId) {
        // badge
        messagingService.getAlertsNotRead(userId).then((badgeCount) => {
          this.setAlertsBadge({ badgeCount })
        })
        // total
        messagingService.getAlertsTotal(userId).then((totalCount) => {
          this.setAlertsTotal(totalCount)
        })
        // alertas
        messagingService.findAlerts(ALERTS_PAGE_SIZE, 1, userId).then(response => {
          this.setAlerts(response)
        })
      }
    },
    /**
     * Si no hay permisos de notificación, se alerta al usuario mediante un snackbar de warning
     * @param {*} actualPermission
     */
    alertNotificationsNeeded(actualPermission) {
      if (actualPermission === 'denied' || actualPermission === 'default') {
        this.showSnackbar({ visible: true, text: this.$t('notifications.notificationsDisabled'), timeout: 9500, style: SnackbarStyle.WARNING })
      }
    },
    /**
     * Cada vez que llega un mensaje de Firebase, se ejecuta esta función con el payload,
     * para ubicar dicho mensaje en el dropdown de notificaciones o en las alertas
     *
     * TO DO: ALERTAS
     *
     * @param {*} payload
     */
    firebaseMessageHandler(payload) {
      const userId = localStorage.getItem('userId')
      if (userId) {
        if (payload && payload.data) {
          switch (payload.data.type) {
            // reporte diferido
            case MESSAGES_TYPES.REPORT:
              this.$store.commit('reportDefer/openReport', payload.data)
              this.addNotification(payload, userId)
              break
            // error
            case MESSAGES_TYPES.ERROR:
              this.$store.commit('snackbar/showSnackbar', {
                visible: true,
                text: payload.data.title,
                style: SnackbarStyle.ERROR
              })
              this.addNotification(payload, userId)
              break
            case MESSAGES_TYPES.REMINDER:
              this.addAlert(payload, userId)
              break
            case MESSAGES_TYPES.ALERT:
              this.addAlert(payload, userId)
              break
            case MESSAGES_TYPES.ALERT_SPEEDING:
              console.log(payload)
              break
            case MESSAGES_TYPES.ALERT_OUT_OF_SPEED_RANGE:
              console.log(payload)
              break
            case MESSAGES_TYPES.INFO:
              if (payload.data.osId) {
                this.$store.commit('messaging/setOSData', {
                  id_command: payload.data.id_command,
                  status: payload.data.status,
                  os: payload.data.os,
                  osId: payload.data.osId,
                  type: payload.data.type
                })
              }
              break
            case "REPORT_PROGRESS":
              console.log(`Progreso del reporte ${((parseInt(payload.data.current) * 100) / parseInt(payload.data.total)).toFixed(2)}`);
              break
            default:
              console.log(payload)
              break
          }
        }
      }
    },
    /**
     * Agrega una nueva notificación al dropdown del navbar
     * @param {*} payload
     * @param {*} userId
     */
    addNotification(payload, userId) {
      this.addNewNotification(payload.data)
      if (this.notificationSound) {
        this.playSound()
      }
      messagingService.getNotificationsTotalCount(userId).then((totalCount) => {
        this.setNotificationsTotalCount(totalCount)
      })
    },
    /**
     * Agrega una nueva alerta
     * TO DO: cambiar sonido
     * @param {*} payload
     * @param {*} userId
     */
    addAlert(payload, userId) {
      this.addNewAlert(payload.data)
      if (this.getAlertSound) {
        this.playSound()
      }
      messagingService.getAlertsTotal(userId).then((totalCount) => {
        this.setAlertsTotal(totalCount)
      })
    },
    /**
     * Elimina la caché guardada de la página
     */
    async updateUI() {
      this.showUpdateUI = false
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    },

    async refreshToken() {
      const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
      if (enterpriseId) {
        await loginService.refreshAndUpdateUserData(enterpriseId, this.$i18n)
      }
    }
  }
}
