// nombre y versión de la base de datos
const DB_NAME = 'GESTYA_UI_DB'
const DB_VERSION = 3

// nombre de los storages de la base de datos
const dbStorages = [
  {
    name: 'svgIcons',
    autoIncrement: false,
    keyPath: 'uiId',
    /*
    indexes: [
      {
        name: 'uiIdIndex',
        props: ['uiId'],
        unique: true
      }
    ]
    */
    indexes: []
  },
  {
    name: 'autoReports',
    autoIncrement: true,
    keyPath: 'id',
    indexes: [
      {
        name: 'nameIndex',
        props: ['name'],
        unique: true
      }
    ]
  }
]

export default {
  DB_NAME,
  DB_VERSION,
  dbStorages
}
