/* eslint-disable indent */

const state = {
    showDialog: false,
    errorMsg: null,
    errorRequestConfig: null,
    fieldsRequired: false,
    autoScreenshot: null
}

// getters
const getters = {
    getShowDialog: (state) => {
        return state.showDialog
    },
    getErrorMsg: (state) => {
        return state.errorMsg
    },
    getErrorRequestConfig: (state) => {
        return state.errorRequestConfig
    },
    getFieldsRequired: (state) => {
        return state.fieldsRequired
    },
    getAutoScreenshot: (state) => {
        return state.autoScreenshot
    }
}

// actions
const actions = {
    setShowDialog ({ commit }, showDialog) {
        commit('commitShowDialog', { showDialog })
    },
    openReportBugDialog ({ commit }) {
        commit('commitShowDialog', { showDialog: true })
    },
    closeReportBugDialog ({ commit }) {
        commit('commitShowDialog', { showDialog: false })
    },
    setErrorMsg ({ commit }, errorMsg) {
        commit('commitErrorMsg', { errorMsg })
    },
    setErrorRequestConfig ({ commit }, errorRequestConfig) {
        commit('commitErrorRequestConfig', { errorRequestConfig })
    },
    setFieldsRequired ({ commit }, fieldsRequired) {
        commit('commitFieldsRequired', { fieldsRequired })
    },
    setAutoScreenshot ({ commit }, autoScreenshot) {
        commit('commitAutoScreenshot', { autoScreenshot })
    },
    resetState ({ commit }) {
        commit('commitShowDialog', { showDialog: false })
        commit('commitErrorMsg', { errorMsg: null })
        commit('commitErrorRequestConfig', { errorRequestConfig: null })
        commit('commitFieldsRequired', { fieldsRequired: false })
        commit('commitAutoScreenshot', { autoScreenshot: null })
    }
}

// mutations
const mutations = {
    commitShowDialog (state, { showDialog }) {
        state.showDialog = showDialog
    },
    commitErrorMsg (state, { errorMsg }) {
        state.errorMsg = errorMsg
    },
    commitErrorRequestConfig (state, { errorRequestConfig }) {
        state.errorRequestConfig = errorRequestConfig
    },
    commitFieldsRequired (state, { fieldsRequired }) {
        state.fieldsRequired = fieldsRequired
    },
    commitAutoScreenshot (state, { autoScreenshot }) {
        state.autoScreenshot = autoScreenshot
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
