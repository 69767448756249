import service from '@/middleware'

const enterpriseApi = {
  async getAll () {
    let result = await service.http.get('/api/enterprise/all')
    return result
  },
  async getAllWithLogo () {
    let result = await service.http.get('/api/enterprise/all-with-logo')
    return result
  },
  async getMultiple (ids) {
    let result = await service.http.post('/api/enterprise/multiple', ids)
    return result
  },
  async getEnterpriseCustomTimeSpan () {
    let result = await service.http.get('/api/enterprise/custom-time-span')
    return result
  },
  async getEnterpriseHarvestStartTime () {
    let result = await service.http.get('/api/enterprise/harvest-start-time')
    return result
  },
  async getReportConfiguration (reportKey) {
    let result = await service.http.post('/api/enterprise/report-configuration', { reportKey })
    return result
  },
  async getAllMapped () {
    let result = await service.http.get('/api/enterprise/all-data-mapped')
    return result.data
  },
  async getEnterpriseStateConfiguration () {
    let result = await service.http.get('/api/enterprise/enterprise-state-configuration')
    return result.data
  },
  ///CATEGORY
  async getTotalCountCategories () {
    try {
      const result = await service.http.get(`/api/enterprise/harvestFrontCategories/total-count`)
      return result.data
    } catch (e) {
      return 0
    }
  },
  async getCategoriesPaginated (pageSize, pageNumber, enterpriseId) {
    let result
    if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0 && enterpriseId) {
      result = await service.http.get(`/api/enterprise/harvestFrontCategories?pageSize=${pageSize}&pageNumber=${pageNumber}&enterpriseId=${enterpriseId}`)
    } else if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0) {
      result = await service.http.get(`/api/enterprise/harvestFrontCategories?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    } else {
      result = await service.http.get(`/api/enterprise/harvestFrontCategories?enterpriseId=${enterpriseId}`)    
    }
    return result
  },
  async deleteCategory (id) {
      const result = await service.http.delete(`/api/enterprise/harvestFrontCategories/${id}`)
      return result
  },
  async getCategoryById (id) {
    const result = await service.http.get(`/api/enterprise/harvestFrontCategories/${id}`)
    return result
  },
  async createCategory (plan) {
    const result = await service.http.post('/api/enterprise/harvestFrontCategories', plan)
    return result
  },
  async updateCategory (plan) {
    const result = await service.http.put('/api/enterprise/harvestFrontCategories', plan)
    return result
  },
  async multipleDeleteCategories (ids) {
    const result = await service.http.put(`/api/enterprise/harvestFrontCategories/multiple-delete`, ids)
    return result
  },
 
}

export default enterpriseApi
