import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ReportDeferComponent',
  data () {
    return {
      visible: false,
      showText: false,
      clickStay: false
    }
  },
  computed: {
    ...mapGetters([
      'getVisible',
      'getShowText'
    ])
  },
  methods: {
    ...mapActions('reportDefer', [
      'setVisible',
      'setShowText'
    ]),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer'
    }),
    overlayClick () {
      console.log('overlay')
      this.showText = !this.clickStay
      this.clickStay = false
    },
    stay () {
      if (this.$store.state.reportDefer.timeoutInstance) {
        clearTimeout(this.$store.state.reportDefer.timeoutInstance)
        // this.$store.state.reportDefer.timeoutInstance = null
      }
      this.showText = false
      this.clickStay = true
    },
    leave () {
      this.closeReportDefer({ visible: false })
    }
  },

  created: function () {
    this.$store.watch(state => state.reportDefer.visible, () => {
      this.visible = this.$store.state.reportDefer.visible
      this.showText = this.$store.state.reportDefer.showText
    })
    this.$store.watch(state => state.reportDefer.showText, () => {
      this.showText = this.$store.state.reportDefer.showText
    })
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  }
}
