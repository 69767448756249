import service from '@/middleware'

const stateApi = {
  async getStatesFilterStructure () {
    let result = await service.http.get('/api/state/all-filter')
    return result
  },
  async getStatesByThingsFilterStructure (thingIds) {
    let result = await service.http.post('/api/state/filter-by-things', { thingIds })
    return result
  },
  async getMultipleDataMapped (stateIds, projection) {
    let result = await service.http.post('/api/state/multiple-data-mapped', { stateIds, projection })
    return result
  },
  async findAllStatesByIds (stateIds) {
    let result = await service.http.post('/api/state/states-by-ids', { stateIds })
    return result.data
  },
  async findAllStatesTypesByIds (stateTypeIds) {
    let result = await service.http.post('/api/state/state-types-by-ids', { stateTypeIds })
    return result.data
  },
  async getStatesTypeByKey (stateTypeKeys) {
    const result = await service.http.post('/api/state/types-by-keys', { stateTypeKeys })
    return result.data ? result.data : []
  },
  async getStatesByEnterpriseId (enterpriseId, typesKeys) {
    const result = await service.http.post('/api/state/get-states-by-enterprise-id', { enterpriseId, typesKeys })
    return result.data ? result.data : {}
  },
  async getStatesTypesByEnterpriseId () {
    const result = await service.http.get('/api/state/state-types-by-enterprise')
    return result.data ? result.data : {}
  }
}

export default stateApi
