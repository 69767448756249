import deepcopy from 'deepcopy'

export const state = () => ({
  alerts: [],
  badge: 0,
  total: 0,
  alertSound: false
})

const getters = {
  getAlerts: (state) => {
    return state.alerts
  },
  getBadge: (state) => {
    return state.badge
  },
  getTotal: (state) => {
    return state.total
  },
  getAlertSound: (state) => {
    return state.alertSound
  }
}

const actions = {
  // establecer un nuevo arreglo de alertas
  setAlerts ({ commit }, alerts) {
    commit('commitAlerts', { alerts })
  },
  // agregar una nueva alerta
  addNewAlert ({ commit, getters }, alert) {
    alert.show = alert.show === 'true'
    alert.delete = alert.delete === 'true'
    const newAlerts = ([alert]).concat(getters.getAlerts)
    const newBadge = getters.getBadge + 1
    commit('commitAlerts', { alerts: newAlerts })
    commit('commitBadge', { badge: newBadge })
  },
  // agregar un arreglo de alertas
  addAlerts ({ commit, getters }, alerts) {
    const newAlerts = [...getters.getAlerts, ...alerts]
    commit('commitAlerts', { alerts: newAlerts })
  },
  // cambiar el valor de lectura de una alerta espcífica
  readAlert ({ commit, getters }, payload) {
    const alerts = deepcopy(getters.getAlerts)
    const alert = alerts.find(a => a._id === payload._id)
    alert.show = !alert.show
    commit('commitAlerts', { alerts })
  },
  // marcar todas las alertas como leídas / no leídas
  readAllAlerts ({ commit, getters }, payload) {
    const alerts = deepcopy(getters.getAlerts)
    alerts.forEach(alert => {
      alert.show = payload.value
    })
    commit('commitAlerts', { alerts })
  },
  // eliminar una alerta
  deleteAlert ({ commit, getters }, payload) {
    const alerts = deepcopy(getters.getAlerts)
    const alert = alerts.find(a => a._id === payload._id)
    alert.delete = true
    const total = getters.getTotal - 1
    const badge = getters.getBadge - 1
    commit('commitAlerts', { alerts })
    commit('commitBadge', { badge })
    commit('commitTotal', { total })
  },
  // eliminar todas las alertas
  deleteAllAlerts ({ commit, getters }) {
    const alerts = deepcopy(getters.getAlerts)
    alerts.forEach(alert => {
      alert.delete = true
    })
    commit('commitAlerts', { alerts })
  },
  // establecer el badge
  setBadge ({ commit }, badge) {
    commit('commitBadge', { badge })
  },
  // incrementar el badge
  increaseBadge ({ commit, getters }) {
    let badge = getters.getBadge + 1
    commit('commitBadge', { badge })
  },
  // reducir el badge
  reduceBadge ({ commit, getters }) {
    let badge = getters.getBadge - 1
    commit('commitBadge', { badge })
  },
  // activar / desactivar el sonido de nueva alerta
  switchAlertSound ({ commit, getters }) {
    const alertSound = !getters.getAlertSound
    commit('commitAlertSound', { alertSound })
  },
  // establecer la cantidad total de alertas
  setAlertsTotal ({ commit }, total) {
    commit('commitTotal', { total })
  },
  // resetear todos los datos (para el logout)
  resetAlerts ({ commit }) {
    commit('commitAlerts', { alerts: [] })
    commit('commitBadge', { badge: 0 })
    commit('commitTotal', { total: 0 })
  }
}

const mutations = {
  commitAlerts (state, { alerts }) {
    state.alerts = alerts
  },
  commitBadge (state, { badge }) {
    state.badge = badge
  },
  commitAlertSound (state, { alertSound }) {
    state.alertSound = alertSound
  },
  commitTotal (state, { total }) {
    state.total = total
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
