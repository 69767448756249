import service from '@/middleware'

const mapReportApi = {
  /**
   * Reporte mapa de todos los tipos -> REPORTE MAPA DEL DASHBOARD DEL HOME
   * @param {*} things
   */
  async getMapReportAll() {
    const result = await service.http.post('/api/current-state/only-map-data')
    return result
  },
  /**
   * Estado actual
   * @param {*} pageSize
   * @param {*} pageNumber
   * @param {*} things
   * METODO NO UTILIZADO. EN CASO DE REUTILIZAR, OCUPAR EL METODO
   * processReportData DEL mapCurrentStateService.js PARA EL FORMATEO
   * DE DATOS, YA QUE ESTE METODO FUE MIGRADO DE APIGATEWAY A ESTE
   * SERVICIO PARA EL REFACTOR DE REPORTES DIFERIDOS (PAGRO-2410)
   */
  async getCurrentStateAll(pageSize, pageNumber, things = []) {
    let result
    if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0) {
      result = await service.http.post(`/api/current-state/report-all?pageSize=${pageSize}&pagenumber=${pageNumber}`, { things })
    } else {
      result = await service.http.post(`/api/current-state/report-all`, { things })
    }
    return result
  },
  /**
   * Reporte mapa (filtrado por tipo y por ids de cosas)
   * @param {*} type
   * @param {*} things
   */
  async getMapReport(type, things = []) {
    const result = await service.http.post('/api/current-state/map-report', { type, things })
    return result
  },
  /**
   * Tabla de estado actual filtrado por tipo (diferido)
   * Se utiliza en el reporte de estado actual de trailers
   * @param {*} type
   * @param {*} things
   * @param {*} route
   */
  async getCurrentStateDeferred(type, things, route) {
    const body = {
      reportQueryFilters: {
        type,
        things,
        route
      },
      filters: things
    }
    try {
      await service.http.post(`/api/current-state/report/deferred`, body)
    } catch (error) {
      console.error(error)
    }
    return null
  },
  /**
   * Tabla de estado actual filtrado por tipo (no diferido)
   * Se utiliza en el reporte de estado actual de trailers
   * @param {*} type
   * @param {*} things
   */
  async getCurrentStateNotDeferred(type, things) {
    const result = await service.http.post(`/api/current-state/report/not-deferred`, { type, things })
    return result
  },
  async getOfflineHarvesters(activity, thingType, workFronts, states) {
    const result = await service.http.post('/api/current-state/offline-harvesters', { activity, thingType, workFronts, states })
    return result
  },
  async getVinculatedTrucks() {
    const result = await service.http.get('/api/current-state/vinculated-trucks')
    return result
  },

  async historyTracks(things, from, hours) {
    const result = await service.http.post('/api/current-state/historyTracks', { things, from, hours });
    return result.data;
  }
}

export default mapReportApi
