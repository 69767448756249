import harvestTargetApi from "@/api/harvestTarget.api"
import thingApi from "@/api/thing.api"
import businessTargetApi from "@/api/businessTarget.api"
import enterpriseApi from "@/api/enterprise.api"
import stateApi from "@/api/state.api"

const harvestTargetService = {
  async getObjectiveProgressByState(activity,workFronts = [], states = [], from, to) {
    const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))

    const mappedStatesIds = states.map(state => state.id)

    let worshiftsCount = undefined

    if (JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      worshiftsCount = JSON.parse(localStorage.getItem('enterpriseTimeSpan')).shift
    } else {
      const enterpriseTimeSpan = (await enterpriseApi.getEnterpriseCustomTimeSpan()).data
      localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
      worshiftsCount = enterpriseTimeSpan.shift
    }

    const extraData = { enterpriseId, worshiftsCount, from, to }
    const { things, thingIds } = getThingsData(workFronts, extraData)
   
    const [thingsProgressByActivity, stateTargetsByThings, thingsState] = await Promise.all([
      harvestTargetApi.getThingsProgressByActivity(things),
      businessTargetApi.getStateTargets(),
      thingApi.getThingsState(thingIds)
    ])

    // Se separan los things que tienen objetivos de los que no, para asi solo trabajar con los que tienen objetivos
    const thingsWithStateTargets = stateTargetsByThings.filter(stateTarget => thingIds.includes(stateTarget.thingId))
    const thingsWithoutStateTargets = []
    thingIds.forEach((thingId) => {
      if (!thingsWithStateTargets.some((item) => item.thingId === thingId)) {
        const thingState = thingsState.find((item) => item.idThing === thingId)
        if (!thingState) return
        thingsWithoutStateTargets.push(thingState)
      }
    });

    const colorIfExistsOfflineHarvester = getColorIfExistsOfflineHarvester(thingsState, thingsWithoutStateTargets)

    // Se genera el objeto situationByState con los estados y sus repectivos objetivos
    const situationByState = getSituationByStateIds(thingsWithStateTargets, mappedStatesIds)

    // Se reemplazan los ids de los estados por sus respectivas keys para luego poder sumar el progreso de cada estado
    await replaceStateIdsByStateKeys(situationByState, colorIfExistsOfflineHarvester)

    // Se suman los progresos de cada estado por cada turno de trabajo y por total
    setTotalAndWorkShiftsProgressByState(situationByState, thingsProgressByActivity, activity)

    // Para los estados que no tienen progreso se les asigna 0 al total y a cada turno de trabajo
    for (const state in situationByState) {
      if (!situationByState[state].totalProgress) {
        situationByState[state].totalProgress = 0
        situationByState[state].progressByWorkShifts = []
        for (let index = 0; index < worshiftsCount; index++) {
          situationByState[state].progressByWorkShifts.push({ progress: 0 })
        }
      }
    }

    return { situationByState, thingsWithoutStateTargets, colorIfExistsOfflineHarvester }
  },

  async getHarvestConfigByHarvestName(harvestName) {
    return await harvestTargetApi.getHarvestConfigByHarvestName(harvestName)
  }
}

const getThingsData = (workFronts, extraData) => {
  const things = []
  const thingIds = []

  if (workFronts.length) {
    workFronts.forEach(workFront => {
      if (workFront.children) {
        workFront.children.forEach(thing => {
          things.push({
            worshiftsCount: extraData.worshiftsCount,
            thingId: thing.id,
            thingTypeKey: thing.typeKey,
            from: extraData.from,
            to: extraData.to,
            enterpriseId: extraData.enterpriseId
          })
          thingIds.push(thing.id)
        })
      }else{
        things.push({
          worshiftsCount: extraData.worshiftsCount,
          thingId: workFront.id,
          thingTypeKey: workFront.typeKey,
          from: extraData.from,
          to: extraData.to,
          enterpriseId: extraData.enterpriseId
        })
        thingIds.push(workFront.id)
      }
    })
  }

  return { things, thingIds }
}

const getColorIfExistsOfflineHarvester = (thingsState, thingsWithoutStateTargets) => {
  let colorIfExistsOfflineHarvester = undefined

  const today = new Date().getTime() / 1000

  thingsState && thingsState.forEach(thing => {
    if (thingsWithoutStateTargets.includes(thing.thingId)) {
      return
    }

    const differenceInMinutes = (today - thing.trackTimestamp) / 60

    if (differenceInMinutes >= 10 && differenceInMinutes <= 60) {
      colorIfExistsOfflineHarvester = 'yellow'
    }
    if (differenceInMinutes > 60) {
      colorIfExistsOfflineHarvester = 'red'
    }
  });

  return colorIfExistsOfflineHarvester
}

const getSituationByStateIds = (thingsWithStateTargets, mappedStatesIds) => {
  const situationByState = {}

  thingsWithStateTargets.forEach(thing => {
    const stateTargetIds = Object.keys(thing.stateTargets)
    stateTargetIds.forEach(stateTargetId => {
      if (mappedStatesIds.includes(stateTargetId) || mappedStatesIds.length === 0) {
        if (situationByState[stateTargetId]) {
          situationByState[stateTargetId].objective += Number(thing.stateTargets[stateTargetId])
        } else {
          situationByState[stateTargetId] = { objective: Number(thing.stateTargets[stateTargetId]) }
        }
      }
    })
  })

  return situationByState
}

const replaceStateIdsByStateKeys = async (situationByState, colorIfExistsOfflineHarvester) => {
  const statesIds = Object.keys(situationByState)

  const statesInformation = await stateApi.findAllStatesTypesByIds(statesIds)

  statesInformation.forEach(state => {
    delete Object.assign(situationByState, {
      [state.key]: {
        objective: situationByState[state._id].objective,
        name: state.name,
        color: colorIfExistsOfflineHarvester ? colorIfExistsOfflineHarvester : state.color,
        stateType: state._id
      }
    })[state._id]
  })
}

const setTotalAndWorkShiftsProgressByState = (situationByState, thingsProgressByActivity, activity) => {
  thingsProgressByActivity.forEach(thing => {
    if (thing.total[activity]) {
      const statesKeys = Object.keys(thing.total[activity])
      statesKeys.forEach(stateKey => {
        if (situationByState[stateKey]) {
          if (situationByState[stateKey].totalProgress) {
            situationByState[stateKey].totalProgress += +(Number(thing.total[activity][stateKey]) / 3600).toFixed(2),

              situationByState[stateKey].progressByWorkShifts = thing.workShifts.map((workShift, index) => {
                if (!Object.keys(workShift).length) return { progress: 0 }
                const progress = workShift[activity] && +(Number(workShift[activity][stateKey]) / 3600).toFixed(2)
                return ({
                  progress: situationByState[stateKey].progressByWorkShifts[index].progress + (progress ? progress : 0),
                })
              })

          } else {
            situationByState[stateKey] = {
              ...situationByState[stateKey],
              totalProgress: +(Number(thing.total[activity][stateKey]) / 3600).toFixed(2),

              progressByWorkShifts: thing.workShifts.map(workShift => {
                const progress = workShift[activity] && +(Number(workShift[activity][stateKey]) / 3600).toFixed(2)
                return ({
                  progress: progress ? progress : 0,
                })
              })

            }
          }
        }
      })
    }
  })
}

export default harvestTargetService