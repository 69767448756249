import router from '@/router'

export const state = () => ({
  visible: false,
  timeoutInstance: null,
  showText: false,
  updateFunction: null
})

const getters = {
  getVisible: (state) => {
    return state.visible
  },
  getShowText: (state) => {
    return state.showText
  }
}

const actions = {
  setVisible({ commit }, visible) {
    commit('commitVisible', { visible })
  },
  setShowText({ commit }, showText) {
    commit('commitShowText', { showText })
  },
  showReportDefer({ commit }, payload) {
    commit('showReportDefer', payload)
  },
  closeReportDefer({ commit }) {
    commit('closeReportDefer')
  },
  openReport({ commit, state }, payload) {
    console.log(state.visible, payload, router.currentRoute)
    if (state.visible && payload.url === router.currentRoute.path) {
      if (state.updateFunction) {
        state.updateFunction(payload.key)
      }
      commit('closeReportDefer')
    }
  }
}

const mutations = {
  commitVisible(state, { visible }) {
    state.visible = visible
    if (!visible) state.showText = false
  },
  commitShowText(state, { showText }) {
    state.showText = showText
  },
  showReportDefer(state, payload) {
    if (state.timeoutInstance) {
      clearTimeout(state.timeoutInstance)
      state.timeoutInstance = null
    }
    state.updateFunction = payload.updateFunction

    state.timeoutInstance = setTimeout(() => {
      this.commit('reportDefer/commitShowText', { showText: true })
    }, 10000)
    state.showText = false
    state.visible = true
  },
  closeReportDefer(state) {
    clearTimeout(state.timeoutInstance)
    state.timeoutInstance = null
    state.visible = false
    state.showText = false
    state.updateFunction = null
  },
  openReport(state, payload) {
    if (state.visible && payload.url === router.currentRoute.path) {
      if (state.updateFunction) {
        state.updateFunction(payload.key)
      }
      this.commit('reportDefer/closeReportDefer')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
