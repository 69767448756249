import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"40%","overlay-opacity":_vm.overlayOpacity},on:{"click:outside":_vm.clickOutside},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),_c(VIcon,{attrs:{"id":"closeButton"},on:{"click":function($event){return _vm.close()}}},[_vm._v("close")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"id":"icon-col"}},[_c(VIcon,{attrs:{"id":"dialog-type-icon","size":"100px","color":_vm.color}},[_vm._v(_vm._s(_vm.type))])],1),_c(VCol,[_c('p',{attrs:{"id":"paragraph-text"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])])],1)],1),(_vm.actionButtons)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"width":"fit-content","color":"primary"},on:{"click":function($event){return _vm.noDialogAction()}}},[_vm._v(_vm._s(_vm.$t('no')))]),_c(VBtn,{attrs:{"width":"fit-content","color":"primary"},on:{"click":function($event){return _vm.yesDialogAction()}}},[_vm._v(_vm._s(_vm.$t('yes')))])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }