import service from '@/middleware'

const authApi = {
  async validateUser (authDto) {
    return await service.http.post('/api/auth/validate', authDto)
  },
  async refreshToken (data) {
    return  await service.http.post('/api/auth/refresh-token', data)
  },
  async refreshTokenSecondFactor (data) {
    return await service.http.post('/api/auth/refresh-token-second-factor', data);
  },
  async logout () {
    return  await service.http.put('/api/auth/logout');
  },
  async getResetPasswordToken (authDto) {
    const result = await service.http.post('/api/auth/reset-password-token', authDto)
    return result
  },
  async resetPassword (body) {
    return await service.http.put('/api/auth/reset-password', body)
  },
  async sendResetPasswordEmail (email, username, config) {
    return await service.http.post('/api/email/send-reset-password', { email, username }, config)
  },
  async validateResetPasswordToken (token) {
    const config = { headers: { token: token } }
    return await service.http.get('/api/auth/validate-password-reset-token', config)
  },

}

export default authApi
