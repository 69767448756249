import service from '@/middleware'

const generalConfigurationApi = {
  /**
   * Obtener todas las cosas del tipo enviado como parámetro
   * @param key
   * @param id
   */
  async getConfig (key, id) {
    let result = await service.http.post('/api/general-configuration/get-config-key-id', { key, id })
    return result
  },

  async getConfigSystemVersion (id) {
    let result = await service.http.post('/api/general-configuration/get-config-system-version', { id })
    return result
  }
}

export default generalConfigurationApi
